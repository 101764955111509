import esLocale from 'element-ui/lib/locale/lang/es'
export default {
    ...esLocale,
    1127: 'elŝuti',
    1128: 'Ensalutu',
    1130: "Monero",
    1131: 'prezo',
    1132: 'Ŝanĝo',
    1133: 'Grafiko',
    1134: "Detaloj",
    1135: 'Kreu vian kriptan biletujon nun',
    1136: '{appName} havas plurajn funkciojn kiuj faras ĝin la plej bona loko por komenci komerci',
    1137: 'Administri vian biletujon',
    1138: 'Aĉetu kaj vendu popularajn ciferecajn valutojn kaj spuru ilin ĉiujn en unu loko',
    1139: "Revenantaj aĉetoj",
    1140: 'Investi en kriptaj moneroj malrapide aranĝante ĉiutagajn, semajnajn aŭ monatajn aĉetojn',
    1141: 'Vault Protekto',
    1142: 'Por plia sekureco, konservu viajn financojn en trezorejo kaj prokrastu eltirojn',
    1143: 'Poŝtelefona aplikaĵo',
    1144: 'Restu sur la merkato kun la aplikaĵo {appName} por Android aŭ iOS',
    1145: 'Kvankam levilforta komerco pri ekzotikaj paroj estas bonega, eĉ pli bone estus havi Discord-servilon plenan de memoj kaj alfa',
    1146: 'Kontaktu nin',

    11081: 'Plataforma de comercio de élite: mantenga el comercio rápido, estable y eficiente',
    11031: 'Soporta múltiples PC y sistemas operativos móviles',
    1126: 'Plataforma de negociación',
    11261: 'Plataforma de negociación de divisas más popular',
    11191: 'Abrir transacciones en cualquier momento y en cualquier lugar',
    nav0: 'Página de inicio',
    nav1: 'comillas',
    nav2: 'transacción de monedas',
    nav3: 'Transacción de contrato',
    nav4: 'gestión financiera',
    nav5: 'Descripción de términos',
    nav6: 'Acuerdo de usuario',
    nav7: 'Política de privacidad',
    nav8: 'Declaración legal',
    nav9: 'Especificación comercial',
    nav10: 'Ayuda para principiantes',
    nav11: 'Preguntas frecuentes',
    nav12: 'Tasa de tarifa de transacción',
    nav13: 'Acerca de nosotros',
    nav14: 'Servicio',
    operate: 'Operación',
    homeTitle_1_1: 'La plataforma de comercio de activos digitales líder en el mundo',
    homeTitle_1_2: 'La plataforma de negociación de activos digitales más popular, acceso a las herramientas de negociación y análisis técnico más potentes y convenientes del mercado. ',
    register: 'Regístrese ahora',
    nowtrans: 'Transacción inmediata',
    logIn: 'Iniciar sesión',
    whats: 'Whats',
    service: 'Servicio al cliente',
    home_1_2: 'Carrod Securities es su divisa de confianza',
    home_1_3: 'Metales preciosos, plataforma CFD',
    home_2_1: 'Los fondos de los clientes se almacenan de forma segura en el fondo fiduciario global segregado bancario de nivel AA',
    home_2_2: 'Menores diferenciales y servidor más estable',
    footer1: `Advertencia de riesgo: Los productos financieros proporcionados por la empresa incluyen contratos por diferencias ('CFD') y otros productos financieros complejos. Operar con CFD conlleva un alto nivel de riesgo, porque el apalancamiento puede ser bueno y malo para usted al mismo tiempo. Por lo tanto, los CFD pueden no ser adecuados para todos los inversores, ya que pueden perder todo el capital de su inversión. Nunca debe invertir dinero que no pueda permitirse perder. Antes de operar con los complejos productos financieros proporcionados, asegúrese de comprender los riesgos involucrados. Se le otorga un derecho limitado, no exclusivo e intransferible para usar la IP proporcionada en este sitio web solo para fines personales y no comerciales relacionados con los servicios prestados en el sitio web. `,
    footer2: 'Tenga en cuenta que la información en este sitio web no está dirigida a residentes de ningún país o jurisdicción que difiera de las leyes y regulaciones descritas en el sitio web. Carrod Securities Co Ltd no es un asesor financiero y solo se le brinda asesoramiento general . No emitirá opiniones o sugerencias sobre la adquisición, tenencia o venta de operaciones de margen. ',
    1: 'Nombre del producto',
    2: 'Tasa de rendimiento diaria esperada',
    3: 'Fecha límite del producto',
    4: 'Importe de la inversión inicial',
    5: 'Operación',
    6: 'compra',
    7: 'Gestión de moneda restante',
    8: 'Gestión financiera flexible, gana todos los días',
    9: 'Sobre nosotros',
    10: 'Acuerdo de usuario',
    11: 'Política de privacidad',
    12: 'Declaración legal',
    13: 'Reglas comerciales',
    14: 'Descripción de términos',
    15: 'Servicio',
    16: 'Ayuda para principiantes',
    17: 'Preguntas frecuentes',
    18: 'Tasa de tarifa de transacción',
    19: 'Sistema de negociación de segundo contrato de moneda digital',
    20: 'Rápido, conveniente, sin prorrateo, riesgo controlable',
    21: 'Introducción al sistema',
    22: 'Con el rápido desarrollo de los futuros de divisas digitales, la mayoría de los usuarios poseen cada vez más activos, y los métodos de negociación de divisas virtuales también se han diversificado. Los usuarios buscan cada vez más métodos de liquidación de transacciones rápidos y eficientes, por lo que el segundo contrato también ha sido muy elogiado. El segundo contrato lanzado por Mutual Cloud es un sistema de negociación para la liquidación de contratos de moneda virtual en unidades de centavos. El sistema admite la negociación de precios de mercado y la negociación de precios limitados; los usuarios pueden comprar opciones de compra y venta, con una pequeña inversión de capital, un umbral bajo y un ciclo de liquidación corto, dividido en 1 minuto, 2 minutos, 3 minutos, etc., y el sistema puede configurarse de forma independiente. El sistema tiene un sistema de control de riesgos riguroso, con las fluctuaciones más altas y más bajas establecidas, si se excede el límite, las probabilidades se restablecerán automáticamente a cero. ',
    23: 'Fuente de línea K',
    24: 'La negociación depende principalmente de las cotizaciones de mercado de los intercambios externos para la negociación',
    25: 'Moneda de soporte',
    251: 'Método de depósito',
    252: 'Depositar monedas',
    26: 'Recarga mediante dirección de billetera',
    27: 'Compra mediante transacción C2C',
    28: 'Satisfacer las necesidades de la plataforma para emitir monedas',
    281: 'Compra mediante transacción OTC',
    282: 'La plataforma no necesita asumir el riesgo de flotación de divisas',
    29: 'Diseño del modelo de contrato',
    30: 'Método de negociación',
    31: 'Ajuste del tiempo de liquidación',
    32: 'Dirección comercial',
    33: 'Control de transacciones',
    34: 'Control de riesgos',
    35: 'límite de compra',
    36: 'Evitar pérdidas a gran escala de los clientes',
    38: 'Establecer la fecha límite para realizar pedidos',
    39: 'Asegurar suficiente tiempo de liquidación para el sorteo',
    40: 'Configurar líneas arriba y abajo',
    41: 'Dispare las probabilidades a cero para evitar transacciones unilaterales',
    42: 'Evite pérdidas a gran escala',
    43: 'Botón de cierre con un clic',
    44: 'Los clientes pueden hacer clic en el botón para cerrar una posición en situaciones extremas',
    46: 'Supervisión del servicio',
    47: 'Monitoreo integral de los servicios para garantizar una respuesta oportuna a las fallas del servicio e iniciar notificaciones por correo electrónico de fallas a tiempo',
    48: 'Soporte de servicio',
    49: 'Nos acercamos a los clientes con el corazón, somos entusiastas, atentos, pacientes y cuidadosos. Contamos con un equipo profesional de I + D de software, una rigurosa supervisión y gestión de la plataforma y un equipo profesional de servicio integral". ',
    50: 'Uno, seleccione par comercial',
    51: 'Dos, consultar precio',
    52: 'Mire el precio de transacción del mercado y elija el precio correcto para realizar un pedido. ',
    53: 'Tres, compra después de completar el pedido',
    54: 'Elija el par de transacciones que se debe comprar (la transacción de moneda a moneda se refiere al uso de un activo digital para intercambiarlo por otro activo digital)',
    55: 'Complete el precio de compra y el volumen de compra, y espere la transacción de mercado. ',
    57: 'Comprenda las transacciones de divisas en segundos',
    58: 'Transacción rápida, segura y confiable',
    59: 'Cotizaciones comerciales',
    60: 'En stock',
    601: 'Proporcionar activos digitales avanzados, CFD y plataformas de comercio de materias primas a inversores globales',
    61: '¿Cuál es el lugar? ',
    62: 'El contado es para futuros. Spot y futuros son similares, la principal diferencia radica en la diferencia de apalancamiento y margen, tiempo de entrega de la transacción, etc. ',
    63: 'Futuros',
    64: '¿Qué son los futuros?',
    65: 'El contrato de futuros, conocido como futuros, es un método de negociación que abarca el tiempo. Al firmar un contrato, el comprador y el vendedor acuerdan entregar una cantidad específica de lugar de acuerdo con el tiempo, el precio y otras condiciones comerciales especificadas. ',
    66: 'Gestión financiera fiduciaria',
    67: '¿Qué es el financiamiento fiduciario?',
    68: "Las finanzas descentralizadas son un tipo de financiación basada en blockchain. No dependen de instituciones financieras como corredores, bolsas o bancos para proporcionar herramientas financieras, sino que utiliza contratos inteligentes en blockchain para realizar actividades financieras",
    69: 'C2C',
    70: '¿Qué es C2C?',
    71: 'C2C, también conocido como "privado a privado" es una forma de transacción, se refiere a la forma de transacción de individuo a individuo',
    72: 'Servicio comercial de ventanilla única',
    73: 'Nombre',
    74: 'Precio',
    75: 'altibajos 24 horas',
    76: 'Volumen 24H',
    77: 'Citas',
    78: 'Agregación estable y segura',
    79: 'Protección de seguridad de grado financiero',
    80: 'Tecnología imparcial de privacidad y confidencialidad a prueba de conocimiento cero para proteger la privacidad del usuario, múltiples firmas, arquitectura en capas y otros diseños de seguridad. ',
    81: 'Motor de emparejamiento de alto rendimiento',
    82: 'Adopte una arquitectura de clúster distribuida avanzada y métodos de desarrollo de microservicios. Admite más de 3 millones de transacciones por segundo simultáneas. ',
    83: '24 horas de servicio ininterrumpido',
    84: 'El servicio al cliente está en línea las 24 horas del día, los siete días de la semana, para responderle varias preguntas. ',
    85: 'Abrir transacciones en cualquier momento y en cualquier lugar',
    86: 'Nueva revisión para satisfacer las necesidades comerciales de varios escenarios en cualquier momento',
    87: '24 * 7 horas para todo clima, modo de operación a tiempo completo, el servicio al cliente responderá sus consultas relacionadas lo antes posible. ',
    88: 'Par comercial',
    89: 'Último precio',
    90: 'Cambio',
    91: 'Precio más alto',
    92: 'Precio más bajo',
    93: 'Volumen',
    94: 'Iniciar sesión',
    95: 'Registrarse',
    fee: {
        1: 'Tasa de tarifa de transacción',
        2: 'Tarifa de transacción de moneda',
        3: 'Tarifa de transacción del contrato'
    },
    law: {
        1: 'Declaración legal',
        2: 'primer artículo',
        3: 'Esta plataforma se compromete a crear un entorno de comercio de activos digitales compatible, seguro y confiable para los usuarios, y prohíbe a los usuarios usar este sitio web para participar en el lavado de dinero, el contrabando, el soborno comercial, la manipulación malintencionada del mercado y otras actividades comerciales ilegales o ilegales ocupaciones. Con este fin, hacemos todo lo posible para formular programas estrictos contra el lavado de dinero y el terrorismo y tomar medidas efectivas de control de riesgos de seguridad para maximizar la protección de los derechos e intereses legítimos de los usuarios, y para mantener una buena reputación de marca en la plataforma. sí mismo',
        4: 'Segundo artículo',
        5: 'Cuando las autoridades competentes presenten los documentos de investigación correspondientes y soliciten a este sitio web que coopere en la investigación de los usuarios designados, o cuando se tomen medidas como el sellado, congelamiento o transferencia de cuentas de usuario, este sitio web verificará la validez y autenticidad de los documentos judiciales Después del sexo, de acuerdo con los requisitos de la autoridad competente determinada por la plataforma para ayudar a proporcionar los datos de usuario correspondientes o realizar las operaciones correspondientes, la fuga de privacidad del usuario, la inoperancia de la cuenta y otras pérdidas causadas a los usuarios, etc., son causadas por esto. sitio web No asumimos ninguna responsabilidad. ',
        6: 'Artículo 3',
        7: 'Todos los usuarios que utilizan los servicios de este sitio web deben saber antes de negociar: los activos digitales no están respaldados por ningún gobierno o banco central, y el precio de los activos digitales fluctúa mucho, la tenencia o el uso de activos digitales tiene un mayor riesgo. Antes de tomar una decisión comercial, debe ser racional y prudente con su propia situación financiera para determinar si es adecuada para usted ',
        8: 'Artículo 4',
        9: 'Se considerará que cualquier persona que inicie sesión en este sitio web de cualquier manera o utilice directa o indirectamente los servicios de este sitio web acepte voluntariamente las limitaciones de esta declaración del sitio web. '
    },
    rules: {
        1: 'Reglas comerciales',
        2: 'Después de crear un pedido, el comprador debe:',
        3: '1, complete el pago dentro del tiempo de pago especificado en el pedido y luego haga clic en "He pagado";',
        4: '2, use mi método de pago de nombre real, ordene el pago de acuerdo con el monto del pedido y la cuenta de pago designada por el pedido para completar el pago del pedido en tiempo real;',
        5: "3. Si elige un método de pago que no sea en tiempo real para pagar el pedido, debe obtener el consentimiento del vendedor por adelantado",
        6: '4. La orden de pago no incluirá "moneda digital, BTC, Bitcoin" y otra información;',
        7: 'Principios que debe seguir el vendedor',
        8: 'Después de que se genera el pedido, el vendedor debe',
        9: '1. Proporcionar una cuenta receptora certificada por la plataforma;',
        10: '2, después de recibir el pago completo del comprador, complete la liberación dentro del tiempo especificado en el pedido;',
        11: '3. Sin el consentimiento del comprador, el servicio de atención al cliente / comprador no estará obligado a cancelar el pedido;',
        12: "4. Sin el consentimiento del comprador, no se permite ninguna objeción al precio del pedido que se ha creado"
    },
    help: {
        1: 'Ayuda para principiantes',
        2: 'Orden límite',
        3: 'Una orden de compra / venta a un precio específico o mejor. ',
        4: 'Orden de mercado',

        5: 'Una orden de compra o venta inmediata al precio de mercado actual. ',
        6: 'Orden de límite de parada de pérdidas',
        7: 'Una orden para vender o cerrar una posición a un precio fijo después de que el mercado alcanza un precio determinado para controlar las pérdidas. ',
        8: 'Orden de precio límite de obtención de beneficios',
        9: 'Similar a una orden stop-limit, también es una orden para vender o cerrar una posición a un precio fijo después de que el mercado alcanza un precio determinado, que se utiliza para controlar las ganancias. ',
        10: 'orden stop loss del mercado',
        11: 'Una orden para vender o cerrar una posición al precio de mercado después de que el mercado alcanza un cierto precio para controlar las pérdidas. ',
        12: 'Orden de obtención de beneficios del precio de mercado',
        13: 'Similar a una orden de stop loss de mercado, también es una orden para vender o cerrar una posición al precio de mercado después de que el mercado alcanza un precio determinado, que se utiliza para controlar las ganancias. ',
        14: '¿Cuál es el margen para abrir una posición? ',
        15: 'El comercio de margen significa que solo necesita pagar un cierto porcentaje del margen para comprar y vender el contrato. Según el apalancamiento seleccionado, el índice de margen es un múltiplo más pequeño del valor del contrato. Puede aumentar sus ganancias, pero también puede exponerlo a riesgos adicionales. ',
        16: '¿Qué es una liquidación y qué es un pinchazo? ',
        17: 'Cuando los fondos restantes en su cuenta sean menores que las ganancias y pérdidas flotantes requeridas por el contrato comercial, su posición será cerrada por la fuerza por el intercambio, también conocido como liquidación. Dado que el precio cuando se cierra la posición se determina de acuerdo con el precio de mercado, cuando el precio de la transacción es extremadamente desfavorable, es posible que no tenga fondos suficientes para cubrir la pérdida de la transacción, lo que da como resultado un valor negativo en su cuenta. Esta situación es llamado posición en corto. '
    },
    96: 'Seleccionar par comercial',
    97: 'Consultar precio',
    98: 'Comprar después de completar el pedido',
    99: 'Inicio de sesión de cuenta',
    100: '¡Bienvenido de nuevo! Inicie sesión con su correo electrónico o número de teléfono ',
    101: 'Buzón',
    102: 'Móvil',
    103: '¿Olvidaste tu contraseña? ',
    104: 'Regístrese ahora',
    105: 'Contraseña',
    106: 'Introduzca su número de teléfono',
    107: 'Error de formato de número de teléfono móvil',
    108: 'Por favor ingrese correo electrónico',
    109: 'Error de formato del buzón',
    110: 'Introduzca una contraseña',
    111: 'La contraseña tiene al menos 6 caracteres',
    112: 'La contraseña es de hasta 32 bits',
    113: 'Idioma y región',
    114: 'Crear cuenta de {appName}',
    115: 'Utilice su correo electrónico o número de teléfono móvil para registrarse',
    116: 'Código de recomendación',
    117: 'De acuerdo',
    118: 'y',
    119: 'Acuerdo de usuario',
    120: 'Política de privacidad',
    121: '¿Registrado? ',
    122: 'Código de verificación',
    123: 'Enviar',
    124: 'Introduzca el código de verificación',
    125: 'El código de verificación debe tener 6 dígitos',
    126: 'Restablecer contraseña',
    127: 'OK',
    128: 'Utilice su correo electrónico o teléfono móvil para restablecer la contraseña de inicio de sesión',
    129: 'Autenticación de nombre real',
    130: 'Completar la autenticación de nombre real ayuda a proteger la seguridad de la cuenta, aumentar los límites de retiro y los permisos de transacción',
    131: 'Ir a autenticación',
    132: 'Autenticación',
    133: 'Se utiliza para iniciar sesión, retirar monedas, recuperar contraseñas, modificar la configuración de seguridad',
    134: 'Verificado',
    135: 'Gestión de contraseñas',
    136: 'Contraseña de inicio de sesión',
    137: 'Contraseña del fondo',
    138: 'Se usa para proteger la seguridad de la cuenta',
    139: 'Se utiliza para proteger la seguridad de los fondos',
    140: 'Configuración personal',
    141: 'Preferencia de color',
    142: 'Configuración',
    143: 'Modificar',
    144: 'Establecer el gráfico de velas, comprar colores al alza y a la baja',
    145: 'Método de fijación de precios',
    146: 'Unidad de valoración de activos de la cuenta',
    147: 'predeterminado',
    148: 'El verde sube y los rojos caen',
    149: 'Rojo arriba y verde abajo',
    150: 'Modificar contraseña de inicio de sesión',
    151: 'Modificar contraseña del fondo',
    152: 'Contraseña anterior',
    153: 'Nueva contraseña',
    154: 'Introduzca la contraseña anterior',
    155: 'Nuevo número de teléfono',
    156: 'Buzón nuevo',
    157: 'Vincular número de teléfono móvil',
    158: 'vincular buzón',
    159: 'Modificar número de teléfono móvil',
    160: 'Modificar buzón',
    161: 'Autenticado',
    162: 'Establecer un apodo',
    163: 'Introduzca su apodo',
    164: 'Cancelar',
    165: 'País',
    166: 'Nombre',
    167: 'Apellido',
    168: 'Tipo de certificado',
    169: 'Número de certificado',
    170: 'Seleccione un país',
    171: 'Por favor ingrese el nombre',
    172: 'Introduzca el apellido',
    173: 'Introduzca el número de identificación',
    174: 'Cargue la foto frontal de la identificación',
    175: 'Sube una foto del reverso de tu identificación',
    176: 'Cargue una foto de su identificación portátil',
    177: 'Enviar',
    178: 'Certificación',
    179: 'ID',
    180: 'Pasaporte',
    181: 'Siguiente',
    182: 'Cargar fotos de identificación y completar la autenticación',
    183: 'Error al cargar',
    184: 'Error de formato de imagen',
    185: 'La imagen es demasiado grande',
    186: 'Cargando',
    187: 'Resumen de activos',
    188: 'Transferir',
    189: 'Retirada',
    190: 'Depositar monedas',
    191: 'Registros financieros',
    192: 'Congelar',
    193: 'Disponible',
    194: 'Total',
    195: 'Ocultar moneda pequeña',
    196: 'Moneda',
    197: 'valoración del USDT',
    198: 'Buscar',
    199: 'Recargar red',
    200: 'Dirección de depósito',
    201: 'dirección de salida de monedas',
    202: 'Cantidad de recarga',
    203: 'Captura de pantalla de depositar monedas',
    204: 'Comentarios',
    205: 'Copiar dirección',
    206: 'Copia exitosa',
    207: 'Regreso',
    208: 'Opcional',
    209: 'Introduzca el número de monedas que se van a recargar',
    210: 'Por favor, un número válido',
    211: 'Introduzca la dirección de moneda',
    212: 'Cargue una captura de pantalla del depósito de monedas',
    213: 'Dirección de retirada',
    214: 'Importe del retiro',
    215: 'Saldo disponible',
    216: 'Todos',
    217: 'Tarifa de tramitación',
    218: 'Por favor ingrese la cantidad de monedas retiradas',
    219: 'Introduzca la contraseña del fondo',
    220: 'La contraseña del fondo debe tener 6 dígitos',
    221: 'Contraseña predeterminada',
    222: '¡Aún no ha encuadernado la billetera correspondiente! ',
    223: 'Ir a agregar',
    224: 'Envío exitoso',
    225: 'Agregar billetera de colección',
    226: 'Dirección',
    227: 'Por favor ingrese a la red',
    228: 'Introduzca la dirección',
    229: 'Red',
    230: 'Red de retirada',
    231: 'La longitud de la dirección es la más baja de 32 bits',
    232: 'Transferencia de fondos',
    233: 'Importe de la transferencia',
    234: 'Por favor ingrese el monto de la transferencia',
    235: 'Equilibrio insuficiente',
    236: 'Se puede transferir',
    237: 'Dirección de deslizamiento',
    238: 'Transferir',
    239: 'Desde',
    240: 'a',
    241: 'Registros financieros',
    242: 'registro de depósito de monedas',
    243: 'Registro de retirada',
    244: 'Transferir registros',
    245: 'Cantidad',
    246: 'Estado',
    247: 'Hora de creación',
    248: 'Confirmar hora',
    249: 'No más',
    250: 'Precio',
    253: 'Par comercial',
    254: 'Cambio',
    255: 'Sin datos todavía',
    256: 'Suma',
    257: 'Alto',
    258: 'Bajo',
    259: 'Volumen',
    260: 'Abrir',
    261: 'Recibir',
    262: 'Cantidad',
    263: 'Negociación de futuros',
    264: 'alcista',
    265: 'Bajista',
    266: 'Nombre del producto',
    267: 'Ver contrato',
    268: 'Importe de compra',
    269: 'Ingrese el monto de la compra',
    270: 'Tarifa de transacción',
    271: 'Compra mínima',
    272: 'Estado',
    273: 'Entrega pendiente',
    274: 'Entregado',
    275: 'Pedido realizado correctamente',
    276: 'Nombre del producto',
    277: 'Pérdidas y ganancias',
    278: 'Precio de compra',
    279: 'Precio de envío',
    280: 'Continuar operando',
    283: 'Beneficio',
    284: 'Confirmar pedido',
    285: 'Órdenes de historial',
    286: 'Pedidos en espera',
    287: 'Hora de creación',
    288: 'Comprar',
    289: 'Vender',
    290: 'Número de transacciones',
    291: 'Importe de la transacción',
    292: 'Contrato',
    293: 'Tiempo de entrega',
    294: 'Precio de envío',
    295: 'segundos',
    296: 'Minutos',
    297: 'Hora',
    298: 'Día',
    299: 'Tiempo restante',
    300: 'Ver más',
    301: 'Transacción de moneda',
    302: 'Importe de venta',
    303: 'Ingrese el monto de la venta',
    304: 'Transacción en moneda fiduciaria',
    305: 'Activos',
    306: 'Cerrar sesión',
    307: 'Registrado correctamente',
    308: 'Introduzca una recomendación',
    309: 'Éxito',
    310: 'Fallido',
    311: 'Confirmando',
    312: 'Método de pago',
    313: 'Dirección de retirada',
    314: 'Eliminar',
    315: 'Operación',
    316: 'Tu billetera',
    317: '¿Está seguro de que desea eliminar? ',
    318: 'Eliminar correctamente',
    319: 'Autenticación fallida',
    320: 'Recertificación',
    330: 'Billetera de retiro de activos digitales',
    331: 'Vincular tarjeta bancaria, retirar efectivo a moneda fiduciaria',
    332: 'Introduzca una dirección válida',
    333: 'Virtual',
    334: 'Forex',
    335: 'Metales',
    1000: 'día',
    1001: 'En curso',
    1002: 'Cerrado',
    1003: 'Reglas',
    1004: 'Ventaja del producto',
    1005: 'Guarda lo que tomes',
    1006: 'Dividendo vencido',
    1007: 'Bonificación diaria',
    1008: 'Interés de depósito en vivo',
    1009: '100% de garantía de capital',
    1010: 'Ingresos normales durante las vacaciones',
    1011: 'Descanso al día siguiente',
    1012: 'Ventaja del producto',
    1013: 'Los miembros depositan 200 000 USDT en la plataforma, eligen un producto de gestión de patrimonio con un período de 14 días y un ingreso diario de 0,5 %, y el ingreso diario es el siguiente: 200 000*0,5 %=1000 USDT Es decir, después de 14 días, usted puede obtener 14,000 USDT en ingresos, y los ingresos se emiten diariamente. Deposite el capital y se devolverá automáticamente a su cuenta al vencimiento.',
    1014: 'Orden de custodia',
    1015: 'Alojamiento',
    1016: 'Completado',
    1017: 'Redimido',
    1018: 'No hay datos todavía',
    1019: 'Importe de la compra',
    1020: 'Tasa de rendimiento diaria',
    1021: 'CONFIANZA.6',
    1022: 'Tiempo de pago',
    1023: 'Fecha de redención',
    1024: 'Caducado',
    1025: 'Recientemente (ingreso diario)',
    1026: 'Tiempo de dividendos',
    1027: 'Todos los días',
    1028: 'Importe de la custodia',
    1029: 'Retorno al vencimiento',
    1030: 'Saldo disponible',
    1031: 'Ingresos totales estimados',
    1032: 'Transferencia de fondos',
    1033: 'Todos',
    1034: 'Límite único',
    1035: 'Tarifa de tramitación',
    1036: 'He leído y acepto',
    1037: 'Acuerdo de servicio de gestión financiera de plataforma',
    1038: 'OK',
    1039: 'Suscripción',
    1040: 'Comprar',
    1041: 'Vender',
    1042: 'Transferencia bancaria',
    1043: 'Por favor ingrese la cantidad',
    1044: 'Categoría de moneda Fiat',
    1045: 'Cantidad vendida',
    1046: 'Máximo disponible para la venta',
    1047: 'Todos',
    1048: 'Precio unitario de referencia',
    1049: 'Su método de pago',
    1050: 'Nombre del banco',
    1051: 'Número de cuenta bancaria',
    1052: 'Nombre',
    1053: 'No ha vinculado el método de pago correspondiente y no se puede vender. ',
    1054: 'Registro de pedido',
    1055: 'Filtro',
    1056: 'Tipo',
    1057: 'Estado',
    1058: 'Procesando',
    1059: 'Aprobado',
    1060: 'Fallido',
    1061: 'Cantidad',
    1062: 'Éxito',
    1063: 'Realización de tarea',
    1064: `Calcular los resultados de hoy`,
    1065: 'Ingresos acumulados',
    1066: 'Orden de detención',
    1067: 'Cuenta fiduciaria no abierta',
    1068: 'Abra una cuenta fiduciaria de comunicaciones, disfrute de ingresos pasivos estables y facilite la apreciación de activos. ',
    1069: 'Solicitar activación significa que lo ha leído y aceptado',
    1070: '"Acuerdo de servicio de gestión financiera de la plataforma"',
    1071: 'Solicitar activación',
    1072: 'Cancelar',
    1073: 'Ejecutando ...',
    1074: 'Redención',
    1075: '¿Estás seguro de que quieres canjearlo? ',
    1076: 'La redención anticipada requiere el pago de daños y perjuicios, que se deducirán esta vez de acuerdo con su tipo de gestión financiera',
    1077: ', ¿Se confirma la redención?',
    1078: 'Enviado',
    1079: 'Ingrese el código de verificación',
    1080: 'Mensaje',
    1081: 'Beneficio',
    1082: 'Tiempo',
    1083: 'Agregar método de pago en moneda legal',
    1084: 'Estado del pedido',
    1085: 'Moneda',
    1086: 'Número de moneda legal',
    1087: 'Número de cuenta bancaria',
    1088: 'Método de recaudación de dinero gordo',
    1089: 'Agregar método de colección',
    1090: 'Método de pago existente',
    1091: 'moneda fiduciaria',
    1092: 'Código bancario',
    1093: '¿Está seguro de que desea eliminar el método de pago?',
    1094: 'Introduzca el número de cuenta bancaria',
    1095: 'Introduzca el nombre del banco',
    1096: 'Introduzca la moneda legal',
    1097: 'Seleccione',
    1098: 'Por favor ingrese su nombre',
    1099: 'Rama',
    1100: 'Ilimitado',
    1101: 'Aún no ha verificado su nombre real',
    1102: 'Libro Blanco',
    1103: 'Nuestras características',
    1104: 'Estamos seguros',
    1105: 'Somos poderosos',
    1106: 'Mejor soporte',
    1107: 'Somos globales',
    1108: 'Cómo funciona',
    1109: 'Usuarios en más de 20 países y regiones están utilizando nuestra plataforma y son ampliamente reconocidos',
    1110: 'Cifrado de datos avanzado',
    1111: 'Rica variedad de criptomonedas',
    1112: 'Más de 90 criptomonedas enumeradas',
    1113: 'Los datos de sus transacciones están protegidos a través del cifrado de extremo a extremo, lo que garantiza que solo usted tenga acceso a su información personal',
    1114: '43 mil millones',
    1115: 'Volumen de negociación de 24 horas',
    1116: '30 millones',
    1117: 'Usuarios registrados que confían en nosotros',
    1118: 'Comisiones de transacción más bajas',
    1119: 'Descargar',
    1120: 'Nombre de la institución financiera',
    1121: 'nombre de la sucursal',
    1122: 'número de sucursal',
    1123: 'Número de cuenta',
    1124: 'Nombre del destinatario',
    1125: 'Kinjo',

    1148: 'La plataforma de criptomonedas más confiable',
    1149: 'Estas son las razones por las que debe elegirnos',
    1150: 'Estamos a salvo',
    1151: 'Tecnología de confidencialidad y privacidad imparcial a prueba de conocimiento cero para proteger la privacidad del usuario, firmas múltiples, arquitectura en capas y otros proyectos de seguridad',
    1152: 'Mejor soporte',
    1153: '24 * 7 horas en cualquier clima, modo operativo a tiempo completo, el servicio al cliente responderá sus preguntas lo antes posible',
    1154: 'somos globales',
    1155: 'Usuarios en más de 20 países y regiones usan nuestra plataforma y son ampliamente reconocidos',
    1156: 'somos poderosos',
    1157: 'La plataforma de negociación de activos digitales más popular, acceso a las herramientas de negociación y análisis técnico más potentes y asequibles del mercado.',
    1158: 'Gran variedad de criptomonedas',
    1159: 'Más de 90 criptomonedas enumeradas',
    1160: 'Cifrado de datos avanzado',
    1161: 'Los datos de sus transacciones están protegidos a través del cifrado de extremo a extremo, lo que garantiza que solo usted tenga acceso a su información personal.',
    1162: 'Admitimos una variedad de las monedas digitales más populares',
    1163: 'Muchos países han establecido transacciones localizadas',
    1164: 'Use moneda legal para comprar activos digitales e ingrese rápidamente al mundo de los activos digitales',
    1165: 'Opere para ir a cualquier parte en cualquier momento',
    1166: 'Escanéame para descargar',
    1167: 'Los tipos de cambio',
    1168: 'Compre y venda más de 250 criptomonedas con tasas de cambio cargadas recientemente',
    1169: 'Manténgase informado con nuestra aplicación y cliente de escritorio',

    1170: 'Nombre',
    1171: 'Precio',
    1172: 'Cambio',
    1173: 'Cuadro',
    1174: 'Comercio',
    1175: 'Detalle',
    1176: 'Nosotros somos',
    1177: 'Seguro',
    1178: 'Nosotros tenemos',
    1179: 'Global',
    1180: 'Poderoso',
    1181: 'Rica variedad de',
    1182: 'criptomoneda',
    1183: 'Datos avanzados',
    1184: 'Cifrado',
    1185: 'Preparado para comenzar',
    1186: 'Somos la plataforma de comercio de activos digitales líder en el mundo. ',
    1187: 'Somos la plataforma de comercio de activos digitales líder en el mundo. Nos esforzamos constantemente por mejorar las funciones de seguridad para garantizar la seguridad de los activos digitales de nuestros clientes. Nuestro modelo es acercar a los clientes proyectos con valor sostenible.',

    1188: 'Suministro total',
    1189: 'Modo de operación de tiempo completo y para todo clima las 24 horas, los 7 días de la semana, el servicio al cliente responderá las consultas relevantes para usted lo antes posible',
    1190: 'Nosotros somos globales',
    1191: 'Nosotros somos fuertes',
    1192: 'La plataforma de negociación de activos digitales más popular, obtenga las herramientas de negociación y análisis técnico más poderosas y convenientes del mercado.',
    1193: 'Gran variedad de criptomonedas',
    1194: 'Los datos de sus transacciones están protegidos con encriptación de extremo a extremo, lo que garantiza que solo usted pueda acceder a su información personal.',
    1195: 'Tarifa mínima de transacción',
    1196: 'Confía en nuestros usuarios registrados',
    1197: 'Volumen de negociación de 24 horas',
    1198: '+42 mil millones',
    1199: '+30 millones',
    1200: 'Varios países han establecido centros de servicios de transacciones localizados para crear un ecosistema de cadena de bloques que integra múltiples formas comerciales.',
    1201: 'Use moneda fiduciaria para comprar activos digitales e ingrese rápidamente al mundo de los activos digitales',
    1202: 'descarga de la aplicación',
    1203: 'Nuestra ventaja',
    1204: 'Rica información sobre divisas y divisas, proporcionando divisas, contratos, transacciones en divisas legales y otros métodos de transacción, mercado profesional y profundidad.。',
    1205: 'Licencia reglamentaria'
}