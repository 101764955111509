import ruLocale from 'element-ui/lib/locale/lang/ru-RU'
export default {
    ...ruLocale,
    1127: 'загрузить',
    1128: 'Войти',
    1130: 'Валюта',
    1131: 'цена',
    1132: 'Изменить',
    1133: 'График',
    1134: 'Подробности',
    1135: 'Создайте свой портфель криптовалют сейчас',
    1136: "{appName} имеет несколько функций, которые делают его лучшим местом для начала торговли",
    1137: 'Управляйте своим портфелем',
    1138: 'Покупайте и продавайте популярные цифровые валюты и отслеживайте их все в одном месте',
    1139: 'Повторяющиеся покупки',
    1140: 'Инвестируйте в криптовалюты медленно, организуя ежедневные, еженедельные или ежемесячные покупки',
    1141: 'Защита хранилища',
    1142: 'Для дополнительной безопасности храните свои средства в хранилище и откладывайте снятие средств',
    1143: 'Мобильное приложение',
    1144: 'Оставайтесь на вершине рынка с приложением {appName} для Android или iOS',
    1145: 'Хотя торговля экзотическими парами с кредитным плечом — это здорово, было бы еще лучше иметь сервер Discord, полный мемов и альфы',
    1146: 'Свяжитесь с нами',

    11081: 'Элитная торговая платформа: продолжайте торговать быстро, стабильно и эффективно',
    11031: 'Поддерживает несколько ПК и мобильных операционных систем',
    1126: 'Торговая платформа',
    11261: 'Самая популярная торговая платформа Forex',
    11191: 'Открывайте транзакции в любое время и в любом месте',
    nav0: 'Домашняя страница',
    nav1: 'кавычки',
    nav2: 'транзакция с монетами',
    nav3: 'Контрактная транзакция',
    nav4: 'финансовый менеджмент',
    nav5: 'Описание терминов',
    nav6: "Пользовательское соглашение",
    nav7: "Политика конфиденциальности",
    nav8: 'Юридическое заявление',
    nav9: 'Торговая спецификация',
    nav10: 'Помощь новичку',
    nav11: 'FAQ',
    nav12: 'Ставка комиссии за транзакцию',
    nav13: 'О нас',
    nav14: 'Сервис',
    operate: 'Операция',
    homeTitle_1_1: 'Ведущая в мире платформа для торговли цифровыми активами',
    homeTitle_1_2: 'Самая популярная платформа для торговли цифровыми активами, доступ к самым мощным и удобным инструментам технического анализа и торговли на рынке. ',
    register: 'Зарегистрируйтесь сейчас',
    nowtrans: 'Транзакция немедленно',
    logIn: 'Логин',
    whats: "WhatsApp",
    service: 'услуга',
    home_1_2: "Carrod Securities - ваша надежная иностранная валюта",
    home_1_3: 'Драгоценные металлы, платформа CFD',
    home_2_1: 'Средства клиентов надежно хранятся в глобальном отдельном целевом фонде банка уровня AA',
    home_2_2: 'Меньшие спреды и более стабильный сервер',
    footer1: `Предупреждение о рисках: финансовые продукты, предоставляемые компанией, включают контракты на разницу цен (CFD) и другие сложные финансовые продукты. Торговля CFD сопряжена с высоким уровнем риска, потому что кредитное плечо может быть для вас одновременно и хорошим, и плохим. Следовательно, CFD могут не подходить для всех инвесторов, потому что они могут потерять всю вашу инвестиционную сумму. Вы никогда не должны вкладывать деньги, которые вы не можете позволить себе потерять. Прежде чем торговать предоставленными комплексными финансовыми продуктами, убедитесь, что вы понимаете связанные с этим риски. Вам предоставляется ограниченное неисключительное и непередаваемое право использовать IP, предоставленный на этом веб-сайте, только в личных и некоммерческих целях, связанных с услугами, предоставляемыми на веб-сайте. `,
    footer2: 'Обратите внимание, что информация на этом веб-сайте не предназначена для жителей какой-либо страны или юрисдикции, которая отличается от законов и правил, описанных на веб-сайте. Carrod Securities Co Ltd не является финансовым консультантом, и вам предоставляются только общие советы . Не будет давать никаких заключений или предложений относительно приобретения, проведения или продажи маржинальной торговли. ',
    1: "Название продукта",
    2: 'Ожидаемая дневная доходность',
    3: 'Срок выпуска продукта',
    4: 'Начальная сумма вложения',
    5: 'Операция',
    6: 'покупка',
    7: 'Управление оставшейся валютой',
    8: 'Гибкое финансовое управление, прибыль каждый день',
    9: 'О нас',
    10: 'Пользовательское соглашение',
    11: 'Политика конфиденциальности',
    12: 'Юридическое заявление',
    13: 'Правила торговли',
    14: "Описание терминов",
    15: "Сервис",
    16: 'Помощь новичкам',
    17: "FAQ",
    18: 'Ставка комиссии за транзакцию',
    19: 'Система торговли вторым контрактом цифровой валюты',
    20: 'Быстро, удобно, без распределения, риск контролируемый',
    21: 'Введение в систему',
    22: 'С быстрым развитием фьючерсов на цифровые валюты большинство пользователей владеют все большим количеством активов, а методы торговли виртуальной валютой также стали разнообразными. Пользователи все чаще выбирают быстрые и эффективные методы расчета транзакций, поэтому второй контракт также получил высокую оценку. Второй контракт, запущенный Mutual Cloud, - это торговая система для расчета контрактов на виртуальную валюту в центах. Система поддерживает торговлю по рыночной цене и торговлю по лимитной цене; пользователи могут покупать опционы колл и пут с небольшими капиталовложениями, низким порогом и коротким расчетным циклом, разделенным на 1 минуту, 2 минуты, 3 минуты и т. Д., И система может настраиваться самостоятельно. Система имеет строгую систему контроля рисков, с установленными максимальными и минимальными колебаниями, при превышении лимита коэффициенты автоматически сбрасываются до нуля. ',
    23: 'K line source',
    24: 'Торговля в основном зависит от рыночных котировок внешних бирж для торговли',
    25: 'Поддерживаемая валюта',
    251: 'Метод депозита',
    252: 'Депозит монеты',
    26: "Пополнить счет через адрес кошелька",
    27: 'Покупка через транзакцию C2C',
    28: 'Удовлетворение потребностей платформы по выпуску монет',
    281: 'Покупка через внебиржевую сделку',
    282: 'Платформа не должна нести риск плавающего валютного курса',
    29: 'Дизайн контрактной модели',
    30: 'Торговый метод',
    31: 'Установка расчетного времени',
    32: 'Торговое направление',
    33: 'Управление транзакциями',
    34: 'Контроль рисков',
    35: 'Buy limit',
    36: 'Предотвратить крупные убытки клиентов',
    38: 'Установить крайний срок размещения заказов',
    39: 'Обеспечьте достаточно времени для ликвидации для розыгрыша',
    40: 'Установить линии вверх и вниз',
    41: 'Снизьте шансы до нуля, чтобы предотвратить односторонние транзакции',
    42: 'Избегайте крупных потерь',
    43: 'Кнопка закрытия одним щелчком',
    44: 'Клиенты могут нажать кнопку, чтобы закрыть позицию в экстремальных ситуациях',
    46: 'Мониторинг услуг',
    47: 'Комплексный мониторинг сервисов для обеспечения своевременного реагирования на сбои сервисов и своевременной отправки уведомлений об ошибках по электронной почте',
    48: 'Сервисная поддержка',
    49: 'Мы искренне подходим к клиентам, мы полны энтузиазма, внимательны, терпеливы и осторожны. У нас есть профессиональная команда разработчиков программного обеспечения, строгий мониторинг и управление платформой, а также профессиональная команда универсального обслуживания. ',
    50: 'Один, выберите торговую пару',
    51: "Два, проверьте цену",
    52: 'Посмотрите на рыночную цену сделки и выберите правильную цену для размещения ордера. ',
    53: 'Три, покупайте после оформления заказа',
    54: 'Выберите пару транзакций, которые необходимо приобрести (транзакция между валютой означает использование одного цифрового актива для обмена на другой цифровой актив)',
    55: 'Введите цену покупки и объем покупки и дождитесь рыночной сделки. ',
    57: 'Анализируйте валютные операции за секунды',
    58: 'Безопасная, надежная, быстрая транзакция',
    59: 'Торговые котировки',
    60: "Есть в наличии",
    601: 'Предоставление глобальным инвесторам передовых цифровых активов, CFD и платформ для торговли сырьевыми товарами',
    61: 'Что это за пятно? ',
    62: 'Спот для фьючерсов. Спот и фьючерсы похожи, основное различие заключается в разнице в кредитном плече и марже, времени доставки транзакции и т. Д. ',
    63: "Фьючерсы",
    64: 'Что такое фьючерсы?',
    65: 'Фьючерсный контракт, называемый фьючерсами, - это метод торговли, который охватывает время. Подписывая контракт, покупатель и продавец соглашаются поставить определенное количество спотов в соответствии с указанным временем, ценой и другими торговыми условиями. ',
    66: 'Доверительный финансовый менеджмент',
    67: 'Что такое трастовое финансирование?',
    68: 'Децентрализованное финансирование - это разновидность финансирования на основе блокчейна. Оно не полагается на финансовые учреждения, такие как брокеры, биржи или банки, в предоставлении финансовых инструментов, но использует смарт-контракты на блокчейне для ведения финансовой деятельности',
    69: 'C2C',
    70: 'Что такое C2C',
    71: 'C2C, также известная как\' частное-частное \', является формой транзакции, она относится к форме транзакции между отдельными лицами',
    72: 'Единый торговый сервис',
    73: "Имя",
    74: 'Цена',
    75: '24 часа взлетов и падений',
    76: '24-часовой объем',
    77: 'Цитаты',
    78: 'Стабильная и безопасная агрегация',
    79: 'Защита финансового уровня',
    80: 'Беспристрастная технология обеспечения конфиденциальности и конфиденциальности с нулевым разглашением для защиты конфиденциальности пользователей, множественных подписей, многоуровневой архитектуры и других схем безопасности. ',
    81: 'Высокопроизводительный механизм согласования',
    82: 'Принять передовую распределенную кластерную архитектуру и методы разработки микросервисов. Поддержка более 3 миллионов транзакций в секунду одновременно. ',
    83: 'Круглосуточная бесперебойная работа',
    84: 'Служба поддержки клиентов работает круглосуточно, семь дней в неделю, чтобы ответить на различные вопросы. ',
    85: 'Открывайте транзакции в любое время и в любом месте',
    86: 'Совершенно новая версия для удовлетворения торговых потребностей в различных сценариях в любое время',
    87: '24 * 7 часов всепогодный, постоянный режим работы, служба поддержки ответит на ваши запросы в кратчайшие сроки. ',
    88: "Торговая пара",
    89: "Последняя цена",
    90: 'Изм.',
    91: "Самая высокая цена",
    92: "Самая низкая цена",
    93: 'Объем',
    94: "Войти",
    95: "Регистр",
    fee: {
        1: 'Ставка комиссии за транзакцию',
        2: 'Комиссия за валютную транзакцию',
        3: 'Комиссия за транзакцию по контракту'
    },
    law: {
        1: 'Юридическое заявление',
        2: 'Первая статья',
        3: 'Эта платформа стремится создать совместимую, безопасную и надежную среду торговли цифровыми активами для пользователей и запрещает пользователям использовать этот веб-сайт для отмывания денег, контрабанды, коммерческого взяточничества, злонамеренных манипуляций на рынке и других незаконных торговых операций или незаконных операций. виды деятельности. С этой целью мы делаем все возможное, чтобы сформулировать строгие программы по борьбе с отмыванием денег и терроризмом, а также принять эффективные меры по контролю рисков безопасности для максимальной защиты законных прав и интересов пользователей, а также для поддержания хорошей репутации бренда на платформе. сам',
        4: 'Вторая статья',
        5: 'Когда компетентные органы представляют соответствующие документы расследования и запрашивают этот веб-сайт о сотрудничестве в расследовании деятельности определенных пользователей, или когда принимаются такие меры, как опечатывание, блокирование или передача учетных записей пользователей, этот веб-сайт будет проверять действительность и подлинность судебных документов. После секса, в соответствии с требованиями компетентного органа, определенного платформой для оказания помощи в предоставлении соответствующих пользовательских данных или выполнении соответствующих операций, утечка конфиденциальности пользователя, неработоспособность учетной записи и другие убытки, причиненные пользователям, и т. Д., Вызваны этим. веб-сайт.Мы не несем никакой ответственности. ',
        6: 'Статья 3',
        7: 'Все пользователи, которые пользуются услугами этого веб-сайта, должны знать перед торговлей: цифровые активы не поддерживаются никаким правительством или центральным банком, и цена цифровых активов сильно колеблется, хранение или использование цифровых активов сопряжено с более высоким риском. Прежде чем принимать торговое решение, вы должны быть рациональны и осмотрительны в отношении своего финансового положения, чтобы определить, подходит ли оно для вас ',
        8: 'Статья 4',
        9: 'Любой, кто входит на этот веб-сайт каким-либо образом или прямо или косвенно пользуется услугами этого веб-сайта, считается добровольно принимающим ограничения этого заявления на веб-сайте. '
    },
    rules: {
        1: 'Торговые правила',
        2: 'После создания заказа покупатель должен:',
        3: '1, завершите платеж в срок, указанный в заказе, а затем нажмите \'Я оплатил',
        4: '2, используйте мой способ оплаты на настоящее имя, заказывайте оплату в соответствии с суммой заказа и платежным счетом, указанным в заказе, чтобы завершить оплату заказа в режиме реального времени;',
        5: '3. Если вы выбираете способ оплаты заказа не в режиме реального времени, вы должны заранее получить согласие продавца',
        6: '4. В платежном поручении не должно быть указано \'цифровая валюта, биткойны, биткойны\' и прочая информация;',
        7: 'Принципы, которым должен следовать продавец',
        8: 'После создания заказа продавец должен',
        9: '1. Предоставьте принимающую учетную запись, сертифицированную платформой;',
        10: '2, после получения полной оплаты покупателем, завершить выпуск в течение времени, указанного в заказе;',
        11: '3. Без согласия покупателя покупатель / служба поддержки клиентов не обязаны отменять заказ;',
        12: '4. Без согласия покупателя возражения против созданной цены заказа не допускаются'
    },
    help: {
        1: "Помощь новичкам",
        2: 'Лимитный ордер',
        3: 'Приказ на покупку / продажу по указанной цене или лучше. ',
        4: 'Рыночный порядок',

        5: 'Приказ на немедленную покупку или продажу по текущей рыночной цене. ',
        6: 'Лимитный стоп-ордер',
        7: 'Приказ на продажу или закрытие позиции по установленной цене после того, как рынок достигнет определенной цены, чтобы контролировать убытки. ',
        8: 'Ордер на получение прибыли по лимитной цене',
        9: 'Подобно стоп-лимитному приказу, это также приказ на продажу или закрытие позиции по установленной цене после того, как рынок достигнет определенной цены, которая используется для контроля прибыли. ',
        10: 'Рыночный стоп-лосс',
        11: 'Приказ на продажу или закрытие позиции по рыночной цене после того, как рынок достигнет определенной цены, чтобы контролировать убытки. ',
        12: 'Ордер на получение прибыли по рыночной цене',
        13: 'Подобно рыночному стоп-лоссу, это также приказ на продажу или закрытие позиции по рыночной цене после того, как рынок достигнет определенной цены, которая используется для контроля прибыли. ',
        14: 'Какая маржа для открытия позиции? ',
        15: 'Маржинальная торговля означает, что вам нужно заплатить только определенный процент маржи для покупки и продажи контракта. В соответствии с выбранным кредитным плечом коэффициент маржи является меньшим кратным стоимости контракта. Это может увеличить вашу прибыль, но также может подвергнуть вас дополнительным рискам. ',
        16: 'Что такое ликвидация и что такое прокол? ',
        17: 'Когда остаток средств на вашем счете меньше, чем плавающая прибыль и убыток, требуемые торговым контрактом, ваша позиция будет принудительно закрыта биржей, что также называется ликвидацией. Поскольку цена при закрытии позиции определяется в соответствии с рыночной ценой, когда цена транзакции крайне неблагоприятна, у вас может не хватить средств для покрытия убытков по транзакции, в результате чего на вашем счете будет отрицательное значение. называется короткой позицией. '
    },
    96: 'Выбрать торговую пару',
    97: 'Проверить цену',
    98: 'Купить после оформления заказа',
    99: 'Вход в учетную запись',
    100: 'С возвращением! Войдите в систему, указав свой адрес электронной почты или номер телефона ',
    101: "Почтовый ящик",
    102: "Мобильный",
    103: 'Забыли пароль? ',
    104: 'Зарегистрируйтесь сейчас',
    105: "Пароль",
    106: "Пожалуйста, введите свой номер телефона",
    107: 'Ошибка формата номера мобильного телефона',
    108: 'Пожалуйста, введите адрес электронной почты',
    109: 'Ошибка формата почтового ящика',
    110: 'Пожалуйста, введите пароль',
    111: 'Пароль не менее 6 символов',
    112: "Пароль до 32 бит",
    113: "Язык и регион",
    114: 'Создать аккаунт {appName}',
    115: 'Используйте для регистрации свой адрес электронной почты или номер мобильного телефона',
    116: 'Код рекомендации',
    117: "Согласен",
    118: 'и',
    119: 'Пользовательское соглашение',
    120: 'Политика конфиденциальности',
    121: 'Зарегистрированы? ',
    122: "Проверочный код",
    123: "Отправить",
    124: "Пожалуйста, введите проверочный код",
    125: "Код подтверждения должен состоять из 6 цифр",
    126: 'Сбросить пароль',
    127: "Хорошо",
    128: 'Используйте свою электронную почту или мобильный телефон, чтобы сбросить пароль для входа',
    129: 'Аутентификация по настоящему имени',
    130: 'Завершение аутентификации с использованием настоящего имени помогает защитить безопасность учетной записи, увеличить лимиты на снятие средств и разрешения на транзакции',
    131: 'Перейти к аутентификации',
    132: "Аутентификация",
    133: 'Используется для входа в систему, снятия монет, получения паролей, изменения настроек безопасности',
    134: "Проверено",
    135: 'Управление паролями',
    136: 'Пароль для входа',
    137: "Пароль фонда",
    138: 'Используется для защиты безопасности учетной записи',
    139: 'Используется для защиты денежных средств',
    140: "Персональные настройки",
    141: 'Цветовые предпочтения',
    142: "Настройки",
    143: "Изменить",
    144: 'Установить свечной график, покупать и покупать вниз цвета',
    145: 'Метод ценообразования',
    146: 'Единица оценки активов счета',
    147: "по умолчанию",
    148: "Зеленый поднимается, а красный опускается",
    149: 'Красный вверх и зеленый вниз',
    150: 'Изменить пароль для входа',
    151: 'Изменить пароль фонда',
    152: 'Старый пароль',
    153: 'Новый пароль',
    154: "Пожалуйста, введите старый пароль",
    155: "Новый номер телефона",
    156: "Новый почтовый ящик",
    157: "Привязать номер мобильного телефона",
    158: "привязать почтовый ящик",
    159: "Изменить номер мобильного телефона",
    160: "Изменить почтовый ящик",
    161: "Подтверждено",
    162: "Установить ник",
    163: 'Пожалуйста, введите свой ник',
    164: "Отменить",
    165: 'Country',
    166: 'Имя',
    167: 'Фамилия',
    168: 'Тип сертификата',
    169: 'Номер сертификата',
    170: "Пожалуйста, выберите страну",
    171: 'Пожалуйста, введите имя',
    172: "Пожалуйста, введите фамилию",
    173: "Пожалуйста, введите идентификационный номер",
    174: "Пожалуйста, загрузите лицевую фотографию удостоверения личности",
    175: 'Пожалуйста, загрузите фотографию обратной стороны вашего удостоверения личности',
    176: 'Загрузите фотографию своего портативного удостоверения личности',
    177: "Отправить",
    178: "Сертификация",
    179: "ID",
    180: 'Паспорт',
    181: "Далее",
    182: 'Загрузить фотографии на документы и пройти аутентификацию',
    183: "Ошибка загрузки",
    184: 'Ошибка формата изображения',
    185: "Изображение слишком велико",
    186: 'Загрузка',
    187: 'Обзор активов',
    188: "Перенос",
    189: "Отказ",
    190: 'Депозит монеты',
    191: 'Финансовые отчеты',
    192: 'Заморозить',
    193: "Доступен",
    194: 'Всего',
    195: "Скрыть мелкую валюту",
    196: "Валюта",
    197: 'Оценка USDT',
    198: 'Поиск',
    199: "Сеть пополнения баланса",
    200: 'Адрес депозита',
    201: 'адрес вывода монеты',
    202: "Сумма пополнения",
    203: 'Скриншот ввода монет',
    204: 'Замечания',
    205: "Копировать адрес",
    206: "Копирование выполнено успешно",
    207: 'Возвращение',
    208: "Необязательно",
    209: 'Введите количество монет для пополнения счета',
    210: 'Укажите действительный номер',
    211: "Пожалуйста, введите адрес валюты",
    212: 'Загрузите снимок экрана с депозитом монет',
    213: "Адрес для вывода",
    214: 'Сумма вывода',
    215: 'Доступный баланс',
    216: 'Все',
    217: 'Плата за обработку',
    218: 'Введите количество снятых монет',
    219: 'Пожалуйста, введите пароль фонда',
    220: "Пароль должен состоять из 6 цифр",
    221: 'Пароль по умолчанию',
    222: 'Вы еще не связали соответствующий кошелек! ',
    223: "Перейти к добавлению",
    224: "Отправлено успешно",
    225: "Добавить кошелек для сбора",
    226: "Адрес",
    227: "Пожалуйста, войдите в сеть",
    228: "Пожалуйста, введите адрес",
    229: "Сеть",
    230: 'Сеть вывода средств',
    231: 'Длина адреса составляет младшие 32 бита',
    232: 'Перевод денежных средств',
    233: 'Сумма перевода',
    234: "Пожалуйста, введите сумму перевода",
    235: 'Недостаточный баланс',
    236: 'Можно передать',
    237: "Направление движения",
    238: "Перенос",
    239: "От",
    240: 'в',
    241: "Финансовые отчеты",
    242: 'запись о депозите',
    243: 'Запись о снятии средств',
    244: "Передать записи",
    245: "Объём",
    246: "Статус",
    247: 'Созданное время',
    248: "Подтвердить время",
    249: 'Больше нет',
    250: 'Цена',
    253: 'Торговая пара',
    254: 'Изм.',
    255: 'Нет данных',
    256: "Итого",
    257: "Высокий",
    258: "Низкий",
    259: 'Объем торгов',
    260: "Открыть",
    261: "Получить",
    262: "Сумма",
    263: 'Торговля фьючерсами',
    264: 'бычий',
    265: "Медвежий",
    266: 'Название продукта',
    267: 'Просмотреть контракт',
    268: "Сумма покупки",
    269: 'Пожалуйста, введите сумму покупки',
    270: 'Комиссия за транзакцию',
    271: 'Минимальная покупка',
    272: "Статус",
    273: "Ожидает доставки",
    274: "Доставлено",
    275: "Заказ успешно размещен",
    276: "Название продукта",
    277: 'Прибыли и убытки',
    278: "Цена покупки",
    279: "Стоимость доставки",
    280: 'Продолжить торговлю',
    283: "Прибыль",
    284: "Подтвердить заказ",
    285: "История заказов",
    286: 'Заказы на удержании',
    287: 'Созданное время',
    288: "Купить",
    289: "Продать",
    290: 'Количество транзакций',
    291: 'Сумма транзакции',
    292: "Контракт",
    293: "Срок поставки",
    294: 'Стоимость доставки',
    295: "секунды",
    296: 'Минуты',
    297: "Час",
    298: 'День',
    299: "Оставшееся время",
    300: "Посмотреть еще",
    301: 'Валютная операция',
    302: "Сумма продажи",
    303: "Введите сумму продажи",
    304: 'Фиатная валютная транзакция',
    305: 'Активы',
    306: 'Выход',
    307: "Зарегистрировано успешно",
    308: 'Введите рекомендацию',
    309: "Успех",
    310: "Ошибка",
    311: 'Подтверждаю',
    312: "Способ оплаты",
    313: "Адрес для вывода",
    314: "Удалить",
    315: "Операция",
    316: "Ваш кошелек",
    317: 'Вы уверены, что хотите удалить? ',
    318: "Удалить успешно",
    319: "Ошибка аутентификации",
    320: 'Повторная сертификация',
    330: "Кошелек для вывода цифровых активов",
    331: 'Привязать карту, вывести наличные в фиатную валюту',
    332: "Пожалуйста, введите действительный адрес",
    333: "Виртуальная валюта",
    334: "Форекс",
    335: "Драгоценные металлы",
    1000: 'день',
    1001: "Выполняется",
    1002: "Закрыто",
    1003: 'Правила',
    1004: 'Преимущество продукта',
    1005: `Сохранить как заберешь`,
    1006: `Срок действия дивидендов истек`,
    1007: 'Ежедневный бонус',
    1008: 'Живые проценты по депозиту',
    1009: "100% гарантия капитала",
    1010: `Нормальный доход в праздничные дни`,
    1011: `Отдыхай на следующий день`,
    1012: 'Преимущество продукта',
    1013: `Участники вносят на платформу 200 000 USDT, выбирают продукт по управлению состоянием со сроком 14 дней и ежедневным доходом 0,5%, а ежедневный доход выглядит следующим образом: 200 000*0,5%=1000USDT То есть через 14 дней вы может получить доход в размере 14 000 долларов США, и доход выдается ежедневно.Внесите основную сумму, и она будет автоматически возвращена на ваш счет по истечении срока.`,
    1014: "Опекунское поручение",
    1015: "Хостинг",
    1016: "Завершено",
    1017: "Искуплено",
    1018: "Нет данных",
    1019: "Сумма покупки",
    1020: "Ежедневная доходность",
    1021: 'ДОВЕРИЕ.6',
    1022: 'Время платежа',
    1023: "Дата погашения",
    1024: `Срок действия истек`,
    1025: 'Недавно (дневной доход)',
    1026: 'Дивидендное время',
    1027: "Каждый день",
    1028: 'Сумма опеки',
    1029: `Возврат по истечении срока`,
    1030: `Доступный остаток`,
    1031: `Предполагаемый общий доход`,
    1032: `Перевод денежных средств`,
    1033: "Все",
    1034: 'Единый лимит',
    1035: `Плата за обработку`,
    1036: `Прочитал и согласился`,
    1037: `Соглашение об услугах финансового управления платформой`,
    1038: "Хорошо",
    1039: "Купить",
    1040: "Купить",
    1041: "Продать",
    1042: "Банковский перевод",
    1043: "Пожалуйста, введите сумму",
    1044: "Категория фиатной валюты",
    1045: "Проданное количество",
    1046: `Максимум, доступный для продажи`,
    1047: "Все",
    1048: 'Справочная цена за единицу',
    1049: "Ваш способ оплаты",
    1050: "Название банка",
    1051: "Номер банковского счета",
    1052: "Имя",
    1053: 'Вы не связали соответствующий способ оплаты и не можете быть проданы. ',
    1054: 'Запись заказа',
    1055: "Фильтр",
    1056: "Тип",
    1057: "Статус",
    1058: "Обработка",
    1059: "Успешно",
    1060: "Ошибка",
    1061: "Количество",
    1062: "Успех",
    1063: "Выполняю задание",
    1064: `Рассчитайте сегодняшние результаты`,
    1065: 'Совокупный доход',
    1066: `Постановление под стражей`,
    1067: `Доверительный счет не открыт`,
    1068: `Откройте доверительный счет для связи, получайте стабильный пассивный доход и упростите оценку активов.`,
    1069: `Подать заявку на активацию означает, что вы ознакомились с ней и согласны с ней`,
    1070: `Соглашение об оказании услуг по управлению финансами платформы`,
    1071: `Подать заявку на активацию`,
    1072: "Отменить",
    1073: 'Выполнение ...',
    1074: "Искупление",
    1075: `Вы уверены, что хотите выкупить его?`,
    1076: `Досрочное погашение требует выплаты заранее оцененных убытков, которые на этот раз будут вычтены в соответствии с вашим типом финансового управления`,
    1077: ' ,Выкуп подтвержден?',
    1078: "Отправлено",
    1079: "Пожалуйста, введите проверочный код",
    1080: 'Подсказка',
    1081: "Прибыль",
    1082: 'Время',
    1083: 'Добавить способ оплаты в официальной валюте',
    1084: "Статус заказа",
    1085: "Валюта",
    1086: 'Число законной валюты',
    1087: "Номер банковского счета",
    1088: "Метод сбора жирных денег",
    1089: 'Добавить метод сбора',
    1090: "Существующий способ оплаты",
    1091: "Фиатная валюта",
    1092: "Код банка",
    1093: 'Вы действительно хотите удалить способ оплаты?',
    1094: "Пожалуйста, введите номер банковского счета",
    1095: 'Пожалуйста, введите название банка',
    1096: "Пожалуйста, введите официальную валюту",
    1097: "Пожалуйста, выберите",
    1098: "Пожалуйста, введите свое имя",
    1099: "Филиал",
    1100: `Без ограничений`,
    1101: `Вы еще не подтвердили свое настоящее имя`,
    1102: 'Белая книга',
    1103: 'Наши особенности',
    1104: 'Мы в безопасности',
    1105: 'Мы сильны',
    1106: 'Лучшая поддержка',
    1107: 'Мы глобальны',
    1108: 'Как это работает',
    1109: 'Пользователи из более чем 20 стран и регионов используют нашу платформу и широко известны',
    1110: 'Расширенное шифрование данных',
    1111: 'Богатое разнообразие криптовалют',
    1112: 'В списке более 90 криптовалют',
    1113: 'Ваши транзакционные данные защищены сквозным шифрованием, что гарантирует, что только у вас есть доступ к вашей личной информации.',
    1114: '43 миллиарда',
    1115: 'объем торгов за 24 часа',
    1116: '30 миллионов',
    1117: 'Зарегистрированные пользователи, которые нам доверяют',
    1118: 'Самая низкая комиссия за транзакцию',
    1119: 'Скачат',
    1120: 'Название финансового учреждения',
    1121: 'название ветки',
    1122: 'номер отделения',
    1123: 'Номер счета',
    1124: 'Имя получателя',
    1125: 'Кинджо',


    1149: 'Самая надежная криптовалютная платформа',
    1149: 'Вот причины, почему вы должны выбрать нас',
    1150: 'Мы в безопасности',
    1151: 'Беспристрастная технология конфиденциальности и конфиденциальности с нулевым разглашением для защиты конфиденциальности пользователей, множественных подписей, многоуровневой архитектуры и других схем безопасности.',
    1152: 'Лучшая поддержка',
    1153: '24 * 7 часов в любую погоду, постоянный режим работы, служба поддержки ответит на ваши запросы как можно скорее',
    1154: 'Мы глобальны',
    1155: 'Пользователи в более чем 20 странах и регионах используют нашу платформу и широко известны ',
    1156: 'Мы сильны',
    1157: 'Самая популярная платформа для торговли цифровыми активами, доступ к самым мощным и удобным инструментам технического анализа и торговли на рынке',
    1158: 'Богатое разнообразие криптовалют',
    1159: ' 90+ криптовалют в списке',
    1160: 'Расширенное шифрование данных',
    1161: 'Данные ваших транзакций защищены сквозным шифрованием, что гарантирует, что только у вас есть доступ к вашей личной информации.',
    1162: 'Мы поддерживаем множество самых популярных цифровых валют',
    1163: 'Многие страны установили локализованную транзакцию.',
    1164: 'Используйте легальную валюту для покупки цифровых активов и быстрого входа в мир цифровых активов',
    1165: 'Торгуйте, чтобы отправиться куда угодно в любое время',
    1166: 'Отсканируй меня, чтобы скачать',
    1167: 'Курсы обмена',
    1168: 'Покупайте и продавайте более 250 криптовалют с недавно загруженными обменными курсами',
    1169: 'Будьте в курсе с нашим приложением и настольным клиентом',
    1170: 'Имя',
    1171: 'Цена',
    1172: 'Изменять',
    1173: 'Диаграмма',
    1174: 'Торговля',
    1175: 'Деталь',
    1176: 'Мы',
    1177: 'Безопасный',
    1178: 'У нас есть',
    1179: 'Глобальный',
    1180: 'Мощный',
    1181: 'Богатое разнообразие',
    1182: 'Криптовалюта',
    1183: 'Расширенные данные',
    1184: 'Шифрование',
    1185: 'rготов начать',
    1186: 'Мы являемся ведущей в мире платформой для торговли цифровыми активами. ',
    1187: 'Мы являемся ведущей в мире платформой для торговли цифровыми активами. Мы постоянно стремимся улучшить функции безопасности, чтобы обеспечить безопасность цифровых активов наших клиентов. Наша модель заключается в предоставлении клиентам проектов с устойчивой ценностью.',

    1188: 'Общее предложение',
    1189: '24 * 7 всепогодный, постоянный режим работы, служба поддержки клиентов ответит на соответствующие запросы в кратчайшие сроки.',
    1190: 'Мы глобальны',
    1191: 'Мы сильны',
    1192: 'Самая популярная платформа для торговли цифровыми активами, получите самые мощные и удобные инструменты технического анализа и торговли на рынке.',
    1193: 'Широкий выбор криптовалют',
    1194: 'Данные ваших транзакций защищены сквозным шифрованием, благодаря чему только вы можете получить доступ к своей личной информации».',
    1195: 'Минимальная комиссия за транзакцию',
    1196: 'Доверьтесь нашим зарегистрированным пользователям',
    1197: 'Объем торгов за 24 часа',
    1198: '+42 миллиарда',
    1199: '+30 миллионов',
    1200: 'Несколько стран создали локализованные центры обслуживания транзакций для создания экосистемы блокчейна, объединяющей несколько бизнес-форм.',
    1201: 'Используйте фиатную валюту для покупки цифровых активов и быстрого входа в мир цифровых активов',
    1202: 'скачать приложение',
    1203: 'Наше преимущество',
    1204: 'Богатая валюта и информация о валюте, предоставляющая валюту, контракты, законные валютные операции и другие методы транзакций, профессиональный рынок и глубину.',
    1205: 'Регулирующая лицензия'
}