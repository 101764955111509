import ptLocale from 'element-ui/lib/locale/lang/pt'
export default {
  ...ptLocale,
  1127: 'baixar',
  1128: 'Login',
  1130: 'Moeda',
  1131: 'preço',
  1132: 'Alterar',
  1133: 'Gráfico',
  1134: 'Detalhes',
  1135: 'Crie seu portfólio de criptomoedas agora',
  1136: '{appName} tem vários recursos que o tornam o melhor lugar para começar a negociar',
  1137: 'Gerencie seu portfólio',
  1138: 'Compre e venda moedas digitais populares e acompanhe-as em um só lugar',
  1139: 'Compras recorrentes',
  1140: 'Invista em criptomoedas lentamente organizando compras diárias, semanais ou mensais',
  1141: 'Proteção de cofre',
  1142: 'Para maior segurança, mantenha seus fundos em um cofre e atrase os saques',
  1143: 'Aplicativo móvel',
  1144: 'Fique no topo do mercado com o aplicativo {appName} para Android ou iOS',
  1145: 'Embora a negociação alavancada em pares exóticos seja ótima, seria ainda melhor ter um servidor Discord cheio de memes e alfa',
  1146: 'Fale conosco',

  11081: 'Plataforma de Negociação Elite: Continue Negociando Rápido, Estável e Eficiente',
  11031: 'Suporta vários PCs e sistemas operacionais móveis',
  1126: 'Plataforma de Negociação',
  11261: 'Plataforma de negociação Forex mais popular',
  11191: 'Abra transações a qualquer hora, em qualquer lugar',
  nav0: 'Home',
  nav1: 'Citações',
  nav2: 'RC Trading',
  nav3: 'Futures Trading',
  nav4: 'Financial',
  nav5: 'TERMES DESCRIÇÃO',
  nav6: 'Contrato de usuário',
  nav7: 'Política de Privacidade',
  nav8: 'Estado legal',
  nav9: 'Normas de Negociação',
  nav10: 'Ajuda do novato',
  nav11: 'FAQ',
  nav12: 'Taxa de negociação',
  nav13: 'Sobre nós',
  nav14: 'Servindo',
  operate: 'Operação',
  homeTitle_1_1: 'Plataforma de negociação digital líder do mundo',
  homeTitle_1_2: 'A plataforma de negociação de ativos digital mais popular, obtenha as mais poderosas e mais convenientes análises técnicas e ferramentas de negociação no mercado. ',
  register: 'Registre-se agora',
  nowtrans: 'Negociação Imediata',
  logIn: 'Login',
  whats: 'whatsapp',
  service: 'Serviço',
  home_1_2: 'Carrod Securities é sua troca estrangeira confiável',
  home_1_3: 'Metais preciosos, diferentes plataformas de contrato',
  home_2_1: 'O armazenamento dos fundos do cliente é armazenado no Fundo Global de Nível do Nível AA isolado',
  home_2_2: 'menor doenças mais estável',
  footer1: 'Dicas de risco: os produtos financeiros da empresa incluem um contrato de tarifa (\'CFDS\') e outros produtos financeiros complexos. O contrato de preço de negociação tem um alto risco porque a alavancagem pode ser benéfica e desfavorável para você. Portanto, o contrato de diferença pode não ser adequado para todos os investidores, pois pode perder todo o seu investimento. Você nunca deve investir na perda de dinheiro. Antes do complexo produto financeiro fornecido pela transação, certifique-se de entender os riscos envolvidos. Você recebeu uma direita limitada de não transferência não translacional, apenas para indivíduos e fins não comerciais relacionados aos serviços prestados no site, usando o IP fornecido neste site. ',
  footer2: 'Por favor, note que as informações sobre este site não estão em resposta a qualquer país ou aos residentes do país ou jurisdição dos regulamentos legais e regulamentos. Carrod Securities CO Ltd não é um consultor financeiro, proporcionando-lhe apenas as recomendações gerais. Não há opiniões ou sugestões serão emitidas ou sugeridas para a aquisição, realização ou venda de transações de margem. ',
  1: 'nome do produto',
  2: 'Taxa de rendimento diário esperado',
  3: 'período do produto',
  4: 'a quantia da rota',
  5: 'operação',
  6: 'Registro & Buy',
  7: 'Yu Coin Finance',
  8: 'Gestão financeira flexível, todos os dias tem o benefício',
  9: 'Sobre nós',
  10: 'Contrato de usuário',
  11: 'Política de Privacidade',
  12: 'declaração legal',
  13: 'Regras de Negociação',
  14: 'cláusulas',
  15: 'Servindo',
  16: 'Ajuda do novato',
  17: 'FAQ',
  18: 'Taxa de negociação',
  19: 'Sistema de negociação de contrato de segunda moeda digital',
  20: 'Rápido, conveniente, descrente, controla de risco',
  21: 'Introdução do sistema',
  22: 'Com o rápido desenvolvimento de futuros monetários digitais, a maioria dos usuários tem mais e mais ativos, e os métodos de negociação de moedas virtuais também mudaram. Os usuários também estão cada vez mais buscando métodos de liquidação de negociação rápida e eficiente. Para este fim, segundo contratos também foram muito respeitados. O segundo contrato lançado pela nuvem mútua é um sistema de negociação que é liquidado em unidades de transações de contrato monetário virtuais. O sistema suporta transações de preço de mercado e limitar os preços; os usuários podem comprar subindo, consulte os direitos de queda, investimento pequeno, limiar baixo, ciclo de liquidação curto, dividido em 1 minuto, 2 minutos, 3 minutos, etc., o sistema pode ser configurado de forma independente. O sistema possui um rigoroso sistema de controle de vento, configuração, menor declínio crescente, excedendo o limite e as probabilidades automáticas. ',
  23: 'K Line Source',
  24: 'A transação depende principalmente do mercado interno externo para negociação',
  25: "Moeda de apoio",
  251: "Entrando no caminho",
  252: 'Redin',
  26: 'Recarregue-o através do endereço da carteira',
  27: 'compra por transação C2C',
  28: 'Conheça as necessidades da plataforma para emitir moedas',
  281: 'Compra através da transação OTC',
  282: 'A plataforma não precisa realizar o risco flutuante de dinheiro',
  29: "Design de modelo de contrato",
  30: 'modo de negociação',
  31: 'configuração de tempo de liquidação',
  32: 'Direção de Negociação',
  33: 'Controle de transação',
  34: 'Controle de risco',
  35: 'Comprar o limite do montante',
  36: 'Prevenção de clientes da perda de massa',
  38: 'Defina a ordem do prazo',
  39: 'Tempo de liquidação suficiente garantido para desenhar',
  40: 'Configure a linha, o declínio',
  41: 'desencadear odds para limpar, prevenir transações unilaterais',
  42: 'Evite perdas em grande escala',
  43: 'Botão de fechamento de um botão',
  44: 'O cliente pode fazer um botão de ligação no caso extremo',
  46: 'Monitor de serviço',
  47: 'Servindo o monitoramento abrangente, garantia de falhas de serviço prontamente, iniciando oportunamente as notificações de mala de falhas',
  48: 'Suporte de serviço',
  49: 'Nós cuidamos dos clientes, somos entusiasmados, concentrados, pacientes, cuidadosos, temos uma equipe de pesquisa de software profissional, gerenciamento de monitoramento de plataforma rigorosa, equipe de serviço profissional de uma parada. ',
  50: 'Primeiro, escolha Negociar direito',
  51: 'Segundo, verificar o preço',
  52: 'Olhando o preço do mercado, selecione o preço apropriado. ',
  53: 'Terceiro, preencha a ordem após a compra',
  54: 'Escolhendo a negociação obrigada a comprar (a transação de moeda é resgatar outro ativo digital) com um ativo digital',
  55: 'Preencha o preço de compra e compre o volume, esperando pelo mercado pagar. ',
  57: 'Seconds para entender a transação de moeda',
  58: "Seguro confiável negociação rapidamente",
  59: 'Citações de transação',
  60: 'Spot',
  601: 'Fornecer ativos digitais avançados, contratos de tarifa e plataformas de negociação de commodities para investidores globais.',
  61: 'O que é local? ',
  62: 'Spot é para futuros. O local e os futuros são semelhantes, a principal diferença é a diferença entre alavancagem e margem, tempo de entrega de transações.',
  63: 'Futuros',
  64: "Quais são os futuros?",
  65: 'Contrato de futuros, referido como futuros, é uma abordagem de transação que salta o tempo. Os compradores e vendedores concordam com o número especificado de local de acordo com o tempo especificado, preço e outras condições de negociação através do contrato de assinatura. ',
  66: 'Finanças de confiança',
  67: 'O que é confiança financeira de confiança?',
  68: "Vá para a centralização é um instrumento financeiro baseado na cadeia de blocos, que não confia em financiadores, intercâmbios ou bancos e outras instituições financeiras para fornecer instrumentos financeiros, mas usando os contratos inteligentes na cadeia de blocos para atividades financeiras .",
  69: 'C2C',
  70: 'O que é C2C',
  71: 'C2C, também conhecido como "par de privado" privado "é uma forma de negociação, refere-se a formas de negociação individuais sobre os indivíduos,',
  72: 'Serviço de Negociação de One-Stop',
  73: 'nome',
  74: 'Preço',
  75: '24h subindo',
  76: "Volume de volume de negócios 24h",
  77: 'citações',
  78: 'Polimerização estável e segura',
  79: 'Proteção de segurança de nível financeiro',
  80: "O conhecimento não-bias zero prova a tecnologia confidencial de privacidade para proteger a privacidade do usuário, múltiplas assinaturas, arquitetura hierárquica e outro design de segurança. ",
  81: 'motor combinado de alto desempenho',
  82: "Adotando arquitetura avançada de cluster distribuída e métodos de desenvolvimento de serviços micro. Suporte para mais de 3 milhões / segundos transações. ",
  83: '24 horas de serviço ininterrupto ',
  84: 'Atendimento ao cliente Sete dias 24 horas por dia on-line, para responder suas perguntas. ',
  85: 'Abra a transação a qualquer hora, em qualquer lugar.',
  86: 'Nova revisão, satisfazer as necessidades de negociação de várias cenas a qualquer momento',
  87: '24 * 7 horas do dia todo, modo de operação em tempo integral, atendimento ao cliente pela primeira vez para responder suas perguntas. ',
  88: 'Negociando direito',
  89: 'o último preço',
  90: 'correndo',
  91: 'o preço mais alto',
  92: 'o preço mais baixo',
  93: 'volume de transporte',
  94: 'Login',
  95: 'registrar',
  fee: {
    1: 'Taxa de negociação',
    2: "taxa de negociação RC",
    3: "taxa de transação do contrato"
  },
  law: {
    1: 'Declaração Legal',
    2: 'Primeiro',
    3: 'Esta plataforma está empenhada em criar um cumprimento, seguro, confiável ambiente de negociação de ativos digitais, que proíbe os usuários de usar este site para lavagem, contrabando, suborno comercial, actividades comerciais maliciosos ou atividades ilegais. Para este fim, nós fazemos o nosso melhor para desenvolver rigorosas anti-lavagem de programas anti-terroristas e tomar medidas eficazes de controle de risco de segurança para maximizar os legítimos direitos e interesses dos usuários, e fazer a plataforma em si manter uma boa reputação da marca',
    4: 'Segundo',
    5: 'Quando a Autoridade apresenta certas o documento pesquisa correspondente, este site estará sujeita à eficácia e trueity do documento judicial quando o usuário designado é investigado, ou para a conta de utilizador Depois do sexo, os dados do usuário correspondente é assistida acordo com os requisitos da plataforma determinada pela plataforma, ou a operação correspondente, assim causados por vazamentos de privacidade do usuário, contas que não podem ser operados e outros prejuízos causados pelo usuário, este site Nós não assumimos qualquer responsabilidade. ',
    6: 'Artigo 3',
    7: 'Onde os usuários que utilizam este site, você deve saber antes que a transação: ativos digitais não são suportadas por qualquer governo ou banco central, e os preços dos ativos digitais têm riscos elevados, segurando ou usando ativos digitais. Antes de tomar uma decisão de transação, você deve racionalmente cuidadosamente a situação financeira para julgar se ele é adequado para você',
    8: 'Artigo 4',
    9: 'Qualquer maneira de login ou diretamente, indiretamente usar o prestador de serviços, considerada a aceitar voluntariamente a declaração deste site. '
  },
  rules: {
    1: 'Regras de Negociação',
    2: 'Depois de criar um pedido, o comprador deve:',
    3: '1, pagamento completo dentro do tempo de pagamento especificado pelo pedido e clique em "Eu paguei"',
    4: '2, use meu método de pagamento de nome real, pague o pagamento do pedido pela conta de pagamento de acordo com as ordens e pedidos; ',
    5: '3, escolha tempo não real para pagar a ordem, você deve obter o vendedor concordado com antecedência',
    6: '4, as ordens de pagamento não devem anotar "Moeda Digital, BTC, Bitcoin" e outras informações',
    7: 'O vendedor deve seguir o princípio',
    8: 'Depois de gerar pedidos, o vendedor deve',
    9: '1, fornecendo uma conta de coleta certificada pela plataforma;',
    10: '2, depois de receber o pagamento do comprador, conclua a liberação dentro da ordem especificada',
    11: '3, não-comprador concordou, não solicitam que os compradores / atendimento ao cliente cancele os pedidos',
    12: '4, o consentimento não comprador, pode não dar uma objeção ao preço do pedido criado'
  },
  help: {
    1: 'Ajuda do novato',
    2: 'Preço limite',
    3: 'Uma ordem que comprou / vendida de um preço designado ou melhor preço. ',
    4: 'Lista de preços de mercado',
    5: 'Um pedido que é comprado ou vendido imediatamente com o preço atual de mercado. ',
    6: 'Limite preço de parada',
    7: 'Uma ordem para o preço vender ou fechar o mercado após o mercado atingir um determinado preço para controlar a perda. ',
    8: 'o preço do preço de verificação de preço',
    9: 'Similarmente à lista de perdas de parada limite, também é usado para definir o preço para vender ou colocar o pedido para o preço de compra para controlar o lucro. ',
    10: "Perda de parada de preço de mercado",
    11: 'Uma ordem para vender ou fechar o mercado após um determinado preço no mercado é usado para controlar a perda. ',
    12: 'O preço do proprietário',
    13: 'Semelhante à lista de perdas de parada de preço de mercado, o mercado é vendido ou um lugar para controlar o lucro após o mercado atinge um determinado preço. ',
    14: 'O que é margem aberta? ',
    15: 'Transações de margem, você só precisa pagar uma determinada proporção de margem, você pode comprar e vender contratos, dependendo da alavancagem selecionada múltipla, a taxa de margem é menor que o valor do valor do contrato. Ele pode ampliar sua renda e também pode ter riscos adicionais. ',
    16: 'Qual é o armazém explosivo O que é um armazém? ',
    17: 'Quando os fundos restantes da sua conta são inferiores ao lucro flutuante exigido pelo contrato de transação, sua posição será posicionada forçosamente pela troca, também conhecida como a explosão. Devido ao preço do encerramento, é determinado de acordo com o preço de mercado. Quando o preço da transação é extremamente pobre, você pode ter uma perda de pagamento para pagar a transação, fazendo com que sua conta seja negativa, o que é chamado de armazém. '
  },
  96: 'Escolhendo negociação é',
  97: 'Mostrar Preço',
  98: 'Fill na ordem e compra',
  99: 'Conta Login',
  100: 'Bem-vindo de volta! Use o seu e-mail ou número de telefone para entrar',
  101: 'caixa de correio',
  102: 'mobile',
  103: 'Esqueceu sua senha? ',
  104: 'Register now',
  105: 'Password',
  106: 'Por favor, digite o número do celular',
  107: 'número móvel Format Error',
  108: 'Por favor, indique a caixa de correio',
  109: 'caixa de correio de erro Format',
  110: 'Por favor insira sua senha',
  111: 'A palavra-passe é de pelo menos 6 bits',
  112: 'A senha é até 32 bits',
  113: 'Idioma e Região',
  114: 'Criando {appname} conta',
  115: 'Registro usando seu e-mail ou número de telefone móvel',
  116: 'Recomendar Code',
  117: 'Concordo',
  118: 'e',
  119: 'Contrato de Usuário',
  120: 'Política de Privacidade',
  121: 'Registro? ',
  122: 'Código de Verificação',
  123: 'Enviar',
  124: 'Por favor, insira o código de verificação',
  125: 'código de verificação deve ser de 6 dígitos',
  126: 'Password Reset',
  127: 'OK',
  128: 'Use o seu e-mail ou telefone para redefinir a senha de login',
  129: 'Nome Verdadeiro Certificação',
  130: 'completa de nomes reais de autenticação ajuda de segurança da conta de proteção, aumento de caixa e transações privilégios',
  131: 'Vá para Certificação',
  132: 'autenticação',
  133: 'Usado para iniciar sessão, moeda, recuperar senha, modificar as configurações de segurança',
  134: 'Verificado',
  135: 'Gerenciamento de senhas',
  136: 'password Login',
  137: 'password fundo',
  138: 'Usado para proteger a segurança da conta',
  139: 'Usado para proteger a segurança de fundos',
  140: 'Configurações pessoais',
  141: 'Preferência de cor',
  142: 'Setting',
  143: 'Modificar',
  144: 'Set o mapa K-line, comprar, comprar a cor',
  145: 'Plan',
  146: 'A unidade de precificação de ativos de contas,',
  147: 'Padrão',
  148: 'Green Bulls Red Bell',
  149: 'Depressa verde cai',
  150: 'Modificar a senha de login',
  151: 'Modificar a senha do fundo',
  152: 'Senha antiga',
  153: 'New Password',
  154: 'Por favor, digite a senha antiga',
  155: 'New número de telemóvel',
  156: 'New Mailbox',
  157: 'Bind o número do celular',
  158: 'Encadernação Mailbox',
  159: 'Modificar o número de telefone',
  160: 'Modificar caixa de correio',
  161: 'Certified',
  162: 'Configurando o apelido',
  163: 'Por favor, digite o seu apelido',
  164: 'Cancelar',
  165: 'País',
  166: 'nome',
  167: 'sobrenome',
  168: 'Tipo de Documento',
  169: 'Document Number',
  170: 'Por favor, selecione um país',
  171: 'Por favor, indique o nome',
  172: 'Por favor, indique o último nome',
  173: 'Por favor, indique o número do documento',
  174: 'Por favor, carregar a foto da frente do documento',
  175: 'Por favor envie a foto do contato',
  176: 'Por favor, vá aos documentos portáteis',
  177: 'Enviar',
  178: 'certificação',
  179: 'cartão de identificação',
  180: 'Passaporte',
  181: 'Next',
  182: 'Carregar de identificação com fotografia, completar a certificação',
  183: 'Carregar Falha',
  184: 'Imagem Format Error',
  185: 'A imagem é muito grande',
  186: 'Carregando',
  187: 'ativos Overview',
  188: 'Turn',
  189: 'Câmara',
  190: 'Redin',
  191: 'Record Financeiro',
  192: 'congelamento',
  193: 'Disponível',
  194: 'Total',
  195: 'Esconder pequenas quantidades de moeda',
  196: 'Moeda',
  197: 'USDT Valorização',
  198: 'Pesquisar',
  199: 'Recarga de rede',
  200: 'Redin Address',
  201: 'End Address',
  202: 'número Redin',
  203: 'Redução tela',
  204: 'Observações',
  205: 'Copiar endereço',
  206: 'Copy Sucesso',
  207: 'Return',
  208: 'Opcional',
  209: 'Por favor, indique o número de cargas',
  210: 'Por favor, efetivamente dormência',
  211: 'Por favor, insira o endereço de perder',
  212: 'Faça o upload da moeda imagem vermelha',
  213: 'Ubin Address',
  214: 'Número de cins',
  215: 'Saldo disponível',
  216: 'Todos',
  217: 'Taxa',
  218: 'Por favor, indique o número de renda',
  219: 'Por favor, digite a senha fundo',
  220: 'A senha fundo deve ser de 6 dígitos',
  221: 'Default Password',
  222: 'Você não vincular a carteira correspondente! ',
  223: 'Go para adicionar',
  224: 'Enviar Sucesso',
  225: 'Adicionar receber carteira',
  226: 'Endereço',
  227: 'Por favor, entrar na rede',
  228: 'Por favor, insira o endereço',
  229: 'Network',
  230: 'Ubin de rede',
  231: 'comprimento de endereço de 32 bits',
  232: 'Apoiada',
  233: 'Escorra o número',
  234: 'Por favor, indique o número de Varia',
  235: 'A quantidade de equilíbrio é ',
  236: 'pode ser transferido',
  237: 'Vire a direção',
  238: 'Volume de negócios',
  239: 'De',
  240: 'a',
  241: 'Record Financeiro',
  242: 'Redin Record',
  243: 'Ubin Record',
  244: 'record Detection',
  245: 'Quantidade',
  246: 'Estado',
  247: 'Criando Time',
  248: 'tempo Confirmar',
  249: 'Não mais',
  250: 'Preço',
  253: 'Moeda',
  254: 'Correndo',
  255: 'dados não',
  256: 'Total',
  257: 'High',
  258: 'Low',
  259: '',
  260: 'Open',
  261: 'Receber',
  262: 'quantidade',
  263: 'negociação de futuros',
  264: 'Olhando para o crescente',
  265: 'olhe para baixo',
  266: 'Nome do produto',
  267: 'Ver o contrato',
  268: 'Comprar a quantidade',
  269: 'Por favor, indique o valor da compra',
  270: 'Negociação Fee',
  271: 'Comprar por menos Buy',
  272: 'Estado',
  273: 'Fique a ser entregue',
  274: 'tenha sido entregue',
  275: 'Adicionar um único sucesso',
  276: 'Nome do produto',
  277: 'Profit',
  278: 'Comprar preço',
  279: 'preço Delivery',
  280: 'Continuar transação',
  283: 'recepção',
  284: 'Confirmar a ordem',
  285: 'ordem histórica',
  286: 'No fim',
  287: 'Criando Time',
  288: 'Comprar',
  289: 'Vendido',
  290: 'O número de transações',
  291: 'quantidade de transações',
  292: 'Contrato',
  293: 'O tempo de entrega',
  294: 'preço Delivery',
  295: 'Segundo',
  296: 'pontos',
  297: 'Time',
  298: 'Deus',
  299: 'Tempo restante',
  300: 'Ver mais',
  301: 'RC Negociação',
  302: 'Vendido a quantidade',
  303: 'Por favor, entrar na lista de Sells',
  304: 'operação francesa',
  305: 'activo',
  306: 'Exit Login',
  307: 'Registro Sucesso',
  308: 'Por favor insira o recomendado?',
  309: 'Sucesso',
  310: 'Falha',
  311: 'confirmação',
  312: 'método de recolha',
  313: 'Cadeia Address',
  314: 'Excluir',
  315: 'Operação',
  316: 'Sua carteira',
  317: 'Determinar eliminação? ',
  318: 'sucesso Excluir',
  319: 'Certificação não passou',
  320: 'Re-certificação',
  330: 'Digital Ativos Caixa Pack',
  331: 'Bind cartão de banco, retirada de francês',
  332: 'Por favor insira um endereço válido',
  333: 'Virtual',
  334: 'Forex',
  335: 'Metais',
  1000: 'Day',
  1001: 'em andamento',
  1002: 'Fechado',
  1003: 'Regulamento',
  1004: 'produto Consultivo',
  1005: 'Disponível com ',
  1006: 'Será que a expansão',
  1007: 'Todos os dias vermelho',
  1008: 'interesse ao vivo',
  1009: 'Fundo de Garantia 100%',
  1010: 'Feriados recepção normal',
  1011: 'No dia seguinte',
  1012: 'Vantagem do produto',
  1013: 'Os membros depositam 200.000 USDT na plataforma, escolhem um produto de gestão de patrimônio com um período de 14 dias e uma receita diária de 0,5%, e a receita diária é a seguinte: 200.000*0,5%=1.000USDT Ou seja, após 14 dias, você pode obter 14.000 USDT em receita, e a receita é emitida diariamente. Deposite o principal e ele será devolvido automaticamente à sua conta no vencimento.',
  1014: 'Alojamento Order',
  1015: 'Alojamento',
  1016: 'Concluído',
  1017: 'resgatou',
  1018: 'dados não',
  1019: 'Compra o valor',
  1020: 'rendimento Dia',
  1021: 'trust.6',
  1022: 'Pagamento Time',
  1023: 'Data de Resgate',
  1024: 'Expired',
  1025: 'Recentemente (Recebido recentes)',
  1026: 'Será que Overview Time',
  1027: 'Todos os dias',
  1028: 'Alojamento quantidade',
  1029: 'para trás Expired',
  1030: 'Saldo disponível',
  1031: 'Espera-se a pagar mais',
  1032: 'Apoiada',
  1033: 'All',
  1034: 'Limite Único',
  1035: 'Taxa',
  1036: 'Eu li e concordo',
  1037: 'Acordo Wealth Management Platform',
  1038: 'OK',
  1039: 'Comprar',
  1040: 'Comprar',
  1041: 'Vendido',
  1042: 'Transferência eléctrica',
  1043: 'Por favor, indique a quantidade',
  1044: 'Categoria francês',
  1045: 'quantidade vendida',
  1046: 'A maior venda',
  1047: 'All',
  1048: 'Referência preço unitário',
  1049: 'Seu método de coleta',
  1050: 'Nome do banco',
  1051: 'Conta Bancária',
  1052: 'Nome',
  1053: 'Você não vincular o método de coleta correspondente e você não pode vendê-lo. ',
  1054: 'Ordem Record',
  1055: 'screening',
  1056: 'tipo',
  1057: 'Estado',
  1058: 'Handling',
  1059: 'a',
  1060: 'Falha',
  1061: 'Quantidade',
  1062: 'Sucesso',
  1063: 'É a tarefa',
  1064: 'os resultados de hoje calcular',
  1065: 'Receita cumulativa',
  1066: 'Alojamento Order',
  1067: 'A conta de confiança não foi aberto',
  1068: 'Abrir uma conta de confiança, desfrutar de benefícios passivos estáveis, tornando valor patrimonial adicionado mais simples. ',
  1069: 'Aplicar para abrir, você leu e concordou em',
  1070: 'Acordo Wealth Management Platform',
  1071: 'Aplicar para abrir',
  1072: 'Cancelar',
  1073: 'Execução ...',
  1074: 'Redemption',
  1075: 'Determinar se a redenção? ',
  1076: 'Pré-redentora requer um padrão, de acordo com o seu tipo financeiro, este será deduzido',
  1077: ', confirmam a redenção? ',
  1078: 'enviou',
  1079: 'Por favor, insira o código de verificação',
  1080: 'Dicas',
  1081: 'recepção',
  1082: 'Time',
  1083: 'Adicionar Francês Método Coleção',
  1084: 'Status Order',
  1085: 'Moeda',
  1086: 'quantidade Francês',
  1087: 'Conta Bancária',
  1088: 'método de coleta de Francês',
  1089: 'Adicionar um método de coleta',
  1090: 'coleções existentes',
  1091: 'Flamin',
  1092: 'Código do banco',
  1093: 'Determinar para apagar o método de coleta? ',
  1094: 'Por favor, indique a conta bancária',
  1095: 'Por favor, indique o nome do banco',
  1096: 'Por favor, indique os franceses',
  1097: 'Por favor, selecione',
  1098: 'Por favor, insira o seu nome',
  1099: 'Filial',
  1100: 'Não há restrição',
  1101: 'Você ainda não o nome real de certificação',
  1102: 'Livro Branco',
  1103: 'Nossos recursos',
  1104: 'Estamos seguros',
  1105: 'Somos poderosos',
  1106: 'Melhor Suporte',
  1107: 'Nós somos globais',
  1108: 'Como funciona',
  1109: 'Usuários em mais de 20 países e regiões estão usando nossa plataforma e são amplamente reconhecidos',
  1110: 'Criptografia de dados avançada',
  1111: 'Rica variedade de criptomoedas',
  1112: 'mais de 90 criptomoedas listadas',
  1113: 'Seus dados de transações são protegidos por meio de criptografia de ponta a ponta, garantindo que somente você tenha acesso às suas informações pessoais.',
  1114: '43 bilhões',
  1115: 'volume de negociação 24h',
  1116: '30 milhões',
  1117: 'Usuários registrados que confiam em nós',
  1118: 'Menores taxas de transação',
  1119: 'Baixar',
  1120: 'Nome da instituição financeira',
  1121: 'nome da filial',
  1122: 'número da sucursal',
  1123: 'Número da conta',
  1124: 'Nome do destinatário',
  1125: 'Kinjo',


  1148: 'A plataforma de criptomoeda mais confiável',
  1149: 'Aqui estão algumas razões pelas quais você deve nos escolher',
  1150: 'Estamos Seguros',
  1151: 'Tecnologia imparcial de privacidade e confidencialidade à prova de conhecimento zero para proteger a privacidade do usuário, várias assinaturas, arquitetura em camadas e outros projetos de segurança',
  1152: 'Melhor Suporte',
  1153: '24*7 horas para qualquer tempo, modo de operação em tempo integral, o atendimento ao cliente responderá às suas perguntas relacionadas o mais rápido possível',
  1154: 'Nós somos globais',
  1155: 'Usuários em mais de 20 países e regiões estão usando nossa plataforma e são amplamente reconhecidos',
  1156: 'Nós somos poderosos',
  1157: 'A plataforma de negociação de ativos digitais mais popular, acesso às ferramentas de análise e negociação técnicas mais poderosas e convenientes do mercado',
  1158: 'Rica variedade de criptomoedas',
  1159: 'Mais de 90 criptomoedas listadas',
  1160: 'Criptografia de dados avançada',
  1161: 'Seus dados de transações são protegidos por meio de criptografia de ponta a ponta, garantindo que somente você tenha acesso às suas informações pessoais.',
  1162: 'Apoiamos uma variedade das moedas digitais mais populares',
  1163: 'Muitos países estabeleceram transações localizadas',
  1164: 'Use moeda legal para comprar ativos digitais e entrar rapidamente no mundo dos ativos digitais',
  1165: 'Negocie para ir a qualquer lugar a qualquer hora',
  1166: 'Escaneie-me para baixar',
  1167: 'Taxas de câmbio',
  1168: 'Compre e venda mais de 250 criptomoedas com upload de taxas de câmbio recentemente',
  1169: 'Fique por dentro do nosso aplicativo e cliente de desktop',

  1170: 'Nome',
  1171: 'Preço',
  1172: 'Mudar',
  1173: 'Gráfico',
  1174: 'Troca',
  1175: 'Detalhe',
  1176: 'Nós estamos',
  1177: 'Seguro',
  1178: 'Nós temos',
  1179: 'Global',
  1180: 'Poderoso',
  1181: 'Rica variedade de',
  1182: 'Criptomoeda',
  1183: 'Dados avançados',
  1184: 'Criptografia',
  1185: 'Pronto para começar',
  1186: 'Somos a principal plataforma de negociação de ativos digitais do mundo. ',
  1187: 'Somos a plataforma líder mundial de negociação de ativos digitais. Nós nos esforçamos constantemente para melhorar os recursos de segurança para garantir a segurança dos ativos digitais de nossos clientes. Nosso modelo é trazer aos clientes projetos com valor sustentável.',
  
  1188: 'Fornecimento Total',
  1189: 'Modo de operação em tempo integral 24*7 em qualquer clima, o atendimento ao cliente responderá a perguntas relevantes para você o mais rápido possível',
  1190: 'Nós somos globais',
  1191: 'Nós somos fortes',
  1192: 'A plataforma de negociação de ativos digitais mais popular, obtenha as ferramentas de análise e negociação técnicas mais poderosas e convenientes do mercado.',
  1193: 'Grande variedade de criptomoedas',
  1194: 'Seus dados de transações são protegidos com criptografia de ponta a ponta, garantindo que somente você possa acessar suas informações pessoais.',
  1195: 'Taxa mínima de transação',
  1196: 'Confie em nossos usuários registrados',
  1197: 'Volume de negociação de 24 horas',
  1198: '+42 bilhões',
  1199: '+30 milhões',
  1200: 'Vários países criaram centros de serviços de transações localizados para criar um ecossistema blockchain que integra vários formulários de negócios',
  1201: 'Use moeda fiduciária para comprar ativos digitais e entrar rapidamente no mundo dos ativos digitais',
  1202: 'Baixar APP',
  1203: 'Nossa vantagem',
  1204: 'Moeda rica e informações sobre moeda, fornecendo moeda, contratos, transações de moeda legal e outros métodos de transação, mercado profissional e profundidade.',
  1205: 'Licença regulamentar'
}