import itLocale from 'element-ui/lib/locale/lang/it'
export default {
  ...itLocale,
  1127: 'scarica',
     1128: 'Login',
     1130: 'Valuta',
     1131: 'prezzo',
     1132: 'Cambia',
     1133: 'Grafico',
     1134: 'Dettagli',
     1135: 'Crea ora il tuo portafoglio di criptovalute',
     1136: '{appName} ha molteplici funzionalità che lo rendono il posto migliore per iniziare a fare trading',
     1137: 'Gestisci il tuo portafoglio',
     1138: "Compra e vendi valute digitali popolari e seguile tutte in un unico posto",
     1139: 'Acquisti ricorrenti',
     1140: 'Investi lentamente in criptovalute organizzando acquisti giornalieri, settimanali o mensili',
     1141: 'Protezione del caveau',
     1142: 'Per una maggiore sicurezza, conserva i tuoi fondi in un caveau e ritarda i prelievi',
     1143: 'App mobile',
     1144: 'Rimani al top del mercato con l\'app {appName} per Android o iOS',
     1145: "Sebbene il trading con leva su coppie esotiche sia fantastico, sarebbe ancora meglio avere un server Discord pieno di meme e alfa",
     1146: 'Contattaci',

  11081: "Piattaforma di trading Elite: continua a fare trading in modo veloce, stabile ed efficiente",
11031: 'Supporta più PC e sistemi operativi mobili',
1126: 'Piattaforma commerciale',
11261: 'Piattaforma di trading Forex più popolare',
11191: 'Apri transazioni sempre e ovunque',
  nav0:'Pagina iniziale',
  nav1:'citazioni',
  nav2:'transazione di monete',
  nav3:'Transazione contrattuale',
  nav4:'gestione finanziaria',
  nav5:"Descrizione dei termini",
  nav6:"Contratto per l'utente",
  nav7:"Informativa sulla privacy",
  nav8:"Dichiarazione legale",
  nav9:'Specifiche commerciali',
  nav10:'Aiuto per principianti',
  nav11:'FAQ',
  nav12:"Tasso di commissione di transazione",
  nav13:'Chi siamo',
  nav14:"Servizio",
  operate:'Operazione',
  homeTitle_1_1:'La piattaforma di trading di asset digitali leader nel mondo',
  homeTitle_1_2:'La piattaforma di trading di asset digitali più popolare, accesso all\'analisi tecnica e agli strumenti di trading più potenti e convenienti sul mercato. ',
  register:'Registrati ora',
  nowtrans:'Transazione immediata',
  logIn:' Entra',
  whats: 'WhatsApp',
  service: 'Servizio clienti',
  home_1_2:'Carrod Securities è la tua valuta estera di fiducia',
  home_1_3:'Metalli preziosi, piattaforma CFD',
  home_2_1:"I fondi dei clienti sono conservati in modo sicuro nel fondo fiduciario segregato bancario globale di livello AA",
  home_2_2:'Spread più bassi e server più stabile',
  footer1: `Avviso di rischio: i prodotti finanziari forniti dalla società includono contratti per differenza ('CFD') e altri prodotti finanziari complessi. Il trading di CFD comporta un alto livello di rischio, perché la leva finanziaria può essere sia positiva che negativa per te allo stesso tempo. Pertanto, i CFD potrebbero non essere adatti a tutti gli investitori, perché potrebbero perdere tutto il capitale di investimento. Non dovresti mai investire denaro che non puoi permetterti di perdere. Prima di negoziare i complessi prodotti finanziari forniti, assicurati di comprendere i rischi coinvolti. Ti viene concesso un diritto limitato, non esclusivo e non trasferibile di utilizzare l'IP fornito su questo sito Web solo per scopi personali e non commerciali relativi ai servizi forniti sul sito Web. `,
  footer2:'Si prega di notare che le informazioni su questo sito Web non sono destinate ai residenti di alcun paese o giurisdizione che differisce dalle leggi e dai regolamenti descritti nel sito Web. Carrod Securities Co Ltd non è un consulente finanziario e ti viene fornita solo una consulenza generale . Non emetterà opinioni o suggerimenti sull\'acquisizione, la detenzione o la vendita di operazioni a margine.',
  1:"Nome prodotto",
  2: "Tasso di rendimento giornaliero previsto",
  3: "Scadenza del prodotto",
  4: "Importo iniziale dell'investimento",
  5:'Operazione',
  6: "acquisto",
  7: "Gestione valuta rimanente",
  8:"Gestione finanziaria flessibile, guadagni ogni giorno",
  9:'Chi siamo',
  10:"Accordo per l'utente",
  11:"Informativa sulla privacy",
  12: "Dichiarazione legale",
  13: "Regole commerciali",
  14: "Descrizione dei termini",
  15:"Servizio",
  16:'Aiuto per principianti',
  17:'Domande frequenti',
  18:"Tasso di commissione di transazione",
  19: "Sistema di negoziazione secondo contratto di valuta digitale",
  20:"Veloce, conveniente, nessuna ripartizione, rischio controllabile",
  21:"Introduzione al sistema",
  22:'Con il rapido sviluppo dei futures sulle valute digitali, la maggior parte degli utenti detiene sempre più risorse e anche i metodi di trading della valuta virtuale sono diventati diversi. Gli utenti ricercano sempre più modalità di regolamento delle transazioni veloci ed efficienti, per questo motivo anche il secondo contratto è stato molto apprezzato. Il secondo contratto lanciato da Mutual Cloud è un sistema di trading per il regolamento di contratti di valuta virtuale in unità di centesimi. Il sistema supporta la negoziazione a prezzo di mercato e la negoziazione a prezzo limite; gli utenti possono acquistare opzioni call e put, con piccolo investimento di capitale, soglia bassa e ciclo di regolamento breve, suddiviso in 1 minuto, 2 minuti, 3 minuti, ecc., e il sistema può essere configurato in modo indipendente. Il sistema ha un rigoroso sistema di controllo del rischio, con le fluttuazioni più alte e più basse impostate, se il limite viene superato, le quote verranno automaticamente azzerate. ',
  23:'Sorgente della linea K',
  24: "Il trading dipende principalmente dalle quotazioni di mercato delle borse esterne per il trading",
  25: "Valuta di supporto",
  251: "Metodo di deposito",
  252: "Monete di deposito",
  26:'Ricarica tramite indirizzo portafoglio',
  27:"Acquisto tramite transazione C2C",
  28: "Soddisfare le esigenze della piattaforma per l'emissione di monete",
  281:'Acquisto tramite transazione OTC,',
  282: "La piattaforma non deve sostenere il rischio di fluttuazione della valuta",
  29: "Progettazione del modello contrattuale",
  30: "Metodo commerciale",
  31:'Impostazione del tempo di regolamento',
  32: "Direzione commerciale",
  33: "Controllo delle transazioni",
  34: "Controllo del rischio",
  35:"Limite di acquisto",
  36: "Prevenire perdite su larga scala da parte dei clienti",
  38: "Fissare il termine per l'immissione degli ordini",
  39:"Garantire un tempo di liquidazione sufficiente per il sorteggio",
  40:'Imposta linee su e giù',
  41:'Azzerare le quote per evitare transazioni unilaterali',
  42: 'Evitare perdite su larga scala',
  43:"Pulsante di chiusura con un clic",
  44:"I clienti possono fare clic sul pulsante per chiudere una posizione in situazioni estreme",
  46: "Monitoraggio del servizio",
  47: "Monitoraggio completo dei servizi per garantire una risposta tempestiva ai guasti dei servizi e avviare tempestivamente le notifiche e-mail di guasto",
  48: 'Assistenza',
  49:'Ci avviciniamo ai clienti con il nostro cuore, siamo entusiasti, attenti, pazienti e attenti. Abbiamo un team di ricerca e sviluppo software professionale, monitoraggio e gestione rigorosi della piattaforma e un team di assistenza professionale one-stop. ',
  50:'Uno, seleziona la coppia di scambio',
  51:"Due, controlla il prezzo",
  52:'Guarda il prezzo della transazione di mercato e scegli il prezzo giusto per effettuare un ordine. ',
  53:'Tre, acquista dopo aver compilato l\'ordine',
  54:"Scegli la coppia di transazioni che deve essere acquistata (la transazione da valuta a valuta si riferisce all'utilizzo di una risorsa digitale per scambiarla con un'altra risorsa digitale)",
  55:'Compila il prezzo di acquisto e il volume di acquisto e attendi la transazione di mercato. ',
  57:'Comprendi le transazioni in valuta in pochi secondi',
  58:"Transazione sicura e affidabile, veloce",
  59: "Quotazioni commerciali",
  60:"Disponibile",
  601: "Fornire asset digitali avanzati, CFD e piattaforme di trading di materie prime agli investitori globali",
  61:'Qual è il punto? ',
  62:'Spot è per i futuri. Spot e futures sono simili, la differenza principale risiede nella differenza di leva finanziaria e margine, tempi di consegna delle transazioni, ecc.',
  63:'Futuri',
  64:'Cosa sono i futuri?',
  65:'Il contratto futures, denominato futures, è un metodo di negoziazione che abbraccia il tempo. Firmando un contratto, l\'acquirente e il venditore accettano di consegnare una Importo specificata di spot in base al tempo, al prezzo e ad altre condizioni commerciali specificati. ',
  66: "Affidati alla gestione finanziaria",
  67:"Cos'è il finanziamento fiduciario?",
  68: "La finanza decentralizzata è un tipo di finanza basata su blockchain. Non si affida a istituzioni finanziarie come broker, borse valori o banche per fornire strumenti finanziari, ma utilizza contratti intelligenti sulla blockchain per condurre attività finanziarie",
  69:'C2C',
  70: "Cos'è C2C",
  71: 'C2C, noto anche come "private-to-private" è una forma di transazione, si riferisce alla forma di transazione da individuo a individuo',
  72: "Servizio commerciale unico",
  73:'Nome',
  74:'Prezzo',
  75: '24H alti e bassi',
  76: 'Volume 24 ore',
  77:'Citazioni',
  78:"Aggregazione stabile e sicura",
  79: "Protezione di sicurezza di livello finanziario",
  80:'Tecnologia imparziale a prova di conoscenza zero e riservatezza per proteggere la privacy degli utenti, firme multiple, architettura a strati e altri progetti di sicurezza. ',
  81:"Motore abbinato ad alte prestazioni",
  82:'Adottare un\'architettura cluster distribuita avanzata e metodi di sviluppo di microservizi. Supporta più di 3 milioni di transazioni al secondo simultanee. ',
  83: 'Servizio ininterrotto 24 ore su 24',
  84:'Il servizio clienti è online 24 ore al giorno, sette giorni alla settimana, per rispondere a varie domande per te. ',
  85:'Transazioni aperte sempre e ovunque',
  86: "Nuova revisione per soddisfare le esigenze commerciali di vari scenari in qualsiasi momento",
  87: '24 * 7 ore per tutte le stagioni, modalità operativa a tempo pieno, il servizio clienti risponderà alle tue richieste correlate il prima possibile. ',
  88: 'Moneta',
  89:'Ultimo prezzo',
  90:'Scambio',
  91:'Prezzo più alto',
  92:'Prezzo più basso',
  93:'Volume',
  94:'Accedi',
  95:'Registrati',
  fee: {
    1:"Tasso di commissione di transazione",
    2: "Commissione per transazione valutaria",
    3: "Commissione per transazione contrattuale"
},
law: {
    1:"Dichiarazione legale",
    2:"Primo articolo",
    3:'Questa piattaforma si impegna a creare un ambiente di scambio di risorse digitali conforme, sicuro e affidabile per gli utenti e vieta agli utenti di utilizzare questo sito Web per impegnarsi in riciclaggio di denaro, contrabbando, corruzione commerciale, manipolazione del mercato dannosa e altre attività di commercio illegale o illegale attività. A tal fine, facciamo del nostro meglio per formulare rigorosi programmi antiriciclaggio e antiterrorismo e adottare misure efficaci di controllo dei rischi per la sicurezza per massimizzare la protezione dei diritti e degli interessi legittimi degli utenti e per mantenere una buona reputazione del marchio sulla piattaforma si',
    4: "Secondo articolo",
    5: 'Quando le autorità competenti producono documenti investigativi corrispondenti e richiedono a questo sito Web di collaborare all\'indagine sugli utenti designati, o quando vengono prese misure come la chiusura, il congelamento o il trasferimento di account utente, questo sito Web verificherà la validità e l\'autenticità degli atti giudiziari Dopo il sesso, in conformità con i requisiti dell\'autorità competente determinata dalla piattaforma per assistere nella fornitura dei dati utente corrispondenti o nell\'esecuzione delle operazioni corrispondenti, la perdita di privacy dell\'utente, l\'inoperabilità dell\'account e altre perdite causate agli utenti, ecc., sono causati da questo sito Web. Non ci assumiamo alcuna responsabilità. ',
    6: "Articolo 3",
    7: 'Tutti gli utenti che utilizzano i servizi di questo sito Web dovrebbero sapere prima di fare trading: le risorse digitali non sono supportate da alcun governo o banca centrale e il prezzo delle risorse digitali varia notevolmente, detenere o utilizzare risorse digitali ha un rischio maggiore. Prima di prendere una decisione di trading, dovresti essere razionale e prudente con la tua situazione finanziaria per determinare se è adatta a te',
    8: "Articolo 4",
    9: 'Chiunque acceda a questo sito Web in qualsiasi modo o utilizzi direttamente o indirettamente i servizi di questo sito Web sarà ritenuto accettare volontariamente i vincoli di questa dichiarazione del sito Web. '
  },
  rules: {
    1:"Regole commerciali",
    2:'Dopo aver creato un ordine, l\'acquirente deve:',
    3: '1, completa il pagamento entro il termine di pagamento specificato nell\'ordine, quindi fai clic su "Ho pagato";',
    4: '2, utilizza il metodo di pagamento del mio nome reale, ordina il pagamento in base all\'importo dell\'ordine e al conto di pagamento designato dall\'ordine per completare il pagamento dell\'ordine in tempo reale;',
    5: "3. Se scegli un metodo di pagamento non in tempo reale per pagare l'ordine, devi ottenere il consenso del venditore in anticipo",
    6: '4. L\'ordine di pagamento non riporta "valuta digitale, BTC, Bitcoin" e altre informazioni;',
    7: "Principi che il venditore dovrebbe seguire",
    8:'Dopo che l\'ordine è stato generato, il venditore dovrebbe',
    9: "1. Fornire un account ricevente certificato dalla piattaforma;",
    10: '2, dopo aver ricevuto l\'intero pagamento dell\'acquirente, completare lo svincolo entro il tempo specificato nell\'ordine;',
    11: '3. Senza il consenso dell\'acquirente, l\'acquirente/il servizio clienti non è tenuto ad annullare l\'ordine;',
    12: "4. Senza il consenso dell'acquirente, non è ammessa alcuna obiezione al prezzo dell'ordine che è stato creato;"
  },
  help: {
    1:'Aiuto per principianti',
    2:'Ordine limite',
    3:'Un ordine di acquisto/vendita a un prezzo specificato o migliore. ',
    4: "Ordine di mercato",
    5: 'Un ordine di acquistare o vendere immediatamente al prezzo di mercato corrente. ',
    6: "Ordine di stop loss limite",
    7: 'Un ordine di vendere o chiudere una posizione a un prezzo prefissato dopo che il mercato ha raggiunto un certo prezzo per controllare le perdite. ',
    8: 'Ordine Take Profit Prezzo Limite',
    9: 'Simile a un ordine stop-limit, è anche un ordine per vendere o chiudere una posizione a un prezzo prefissato dopo che il mercato ha raggiunto un certo prezzo, che viene utilizzato per controllare il profitto. ',
    10: "Ordine di stop loss del mercato",
    11: 'Un ordine di vendere o chiudere una posizione al prezzo di mercato dopo che il mercato ha raggiunto un certo prezzo per controllare le perdite. ',
    12: 'Ordine Take Profit prezzo di mercato',
    13: 'Simile a un ordine di stop loss di mercato, è anche un ordine per vendere o chiudere una posizione al prezzo di mercato dopo che il mercato ha raggiunto un certo prezzo, che viene utilizzato per controllare il profitto. ',
    14:'Qual è il margine per aprire una posizione? ',
    15: 'Il trading con margine significa che devi pagare solo una certa percentuale del margine per acquistare e vendere il contratto. In base alla leva selezionata, il rapporto del margine è un multiplo più piccolo del valore del contratto. Può amplificare i tuoi profitti, ma può anche esporti a ulteriori rischi. ',
    16:'Cos\'è una liquidazione e cos\'è una foratura? ',
    17: 'Quando i fondi rimanenti nel tuo conto sono inferiori ai profitti e alle perdite fluttuanti richiesti dal contratto di trading, la tua posizione sarà forzatamente chiusa dallo scambio, noto anche come liquidazione. Poiché il prezzo alla chiusura della posizione è determinato in base al prezzo di mercato, quando il prezzo della transazione è estremamente sfavorevole, potresti non avere fondi sufficienti per coprire la perdita della transazione, con conseguente valore negativo sul tuo conto. chiamato shorting di posizione. '
  },
  96:'Seleziona coppia di scambio',
    97:'Verifica prezzo',
    98:'Acquista dopo aver compilato l\'ordine',
    99:'Accesso all\'account',
    100: 'Bentornato! Accedi con la tua email o numero di telefono',
    101:'Casella postale',
    102: 'Cellulare',
    103:'Hai dimenticato la password? ',
    104:'Registrati ora',
    105:'Password',
    106:'Inserisci il tuo numero di telefono',
    107:"Errore di formato del numero di cellulare",
    108:'Inserire l\'e-mail',
    109:'Errore formato casella di posta',
    110:'Inserire una password',
    111:'La password è di almeno 6 caratteri',
    112:'La password è fino a 32 bit',
    113: "Lingua e regione",
    114:'Crea un account {appName}',
    115:'Usa la tua email o il tuo numero di cellulare per registrarti',
    116: "Codice di raccomandazione",
    117:'D\'accordo',
    118: 'e',
    119: "Accordo per l'utente",
    120:"Informativa sulla privacy",
    121:'Registrato? ',
    122: "Codice di verifica",
    123:'Invia',
    124:'Inserire il codice di verifica',
    125:"Il codice di verifica deve essere di 6 cifre",
    126:'Reimposta password',
    127:'Va bene',
    128:'Utilizza la tua e-mail o il cellulare per reimpostare la password di accesso',
    129:'Autenticazione con nome reale',
    130:"Completare l'autenticazione del nome reale aiuta a proteggere la sicurezza dell'account, aumentare i limiti di prelievo e le autorizzazioni di transazione",
    131:'Vai all\'autenticazione',
    132:'Autenticazione',
    133:'Utilizzato per accedere, prelevare monete, recuperare password, modificare impostazioni di sicurezza',
    134:'Verificato',
    135:'Gestione password',
    136:'Password di accesso',
    137:'Password del fondo',
    138:'Utilizzato per proteggere la sicurezza dell\'account',
    139:'Utilizzato per proteggere la sicurezza dei fondi',
    140:'Impostazioni personali',
    141:'Preferenza colore',
    142:'Impostazioni',
    143:'Modifica',
    144: "Imposta il grafico a candele, acquista e riduci i colori",
    145: "Metodo di prezzo",
    146:'Unità di valutazione delle attività del conto,',
    147:'predefinito',
    148: "Il verde sale e il rosso scende",
    149: "Rosso su e verde giù",
    150:'Modifica password di accesso',
    151:'Modifica password fondo',
    152:'Vecchia password',
    153:'Nuova password',
    154:'Inserire la vecchia password',
    155:'Nuovo numero di telefono',
    156:'Nuova casella di posta',
    157:'Associa numero di cellulare',
    158:'associa casella di posta',
    159:'Modifica numero di cellulare',
    160:'Modifica casella di posta',
    161:'Autenticato',
    162:'Imposta un soprannome',
    163:'Per favore inserisci il tuo nickname',
    164:'Annulla',
    165: 'Paese',
    166:'Nome',
    167:'Cognome',
    168: "Tipo di certificato",
    169:"Numero di certificato",
    170:'Seleziona un paese',
    171:'Inserire il nome',
    172:'Inserire il cognome',
    173:'Inserire il numero ID',
    174:'Si prega di caricare la foto anteriore del documento d\'identità',
    175:'Carica una foto del retro del tuo documento d\'identità',
    176:"Carica una foto del tuo documento d'identità portatile",
    177:'Invia',
    178: 'Certificazione',
    179:'ID',
    180:'Passaporto',
    181:'Avanti',
    182:'Carica fototessere e completa autenticazione',
    183:'Caricamento fallito',
    184:"Errore formato immagine",
    185:"L'immagine è troppo grande",
    186:'Caricamento',
    187: "Panoramica delle risorse",
    188: 'Trasferimento',
    189: 'Ritiro',
    190: "Monete di deposito",
    191: "Registri finanziari",
    192:'Congelare',
    193:'Disponibile',
    194: 'Totale',
    195:"Nascondi piccola valuta",
    196: 'Valuta',
    197: "Valutazione USDT",
    198:'Cerca',
    199: "Rete di ricarica",
    200:'Indirizzo di deposito',
    201: 'indirizzo moneta fuori',
    202:'Ricarica importo',
    203: "Screenshot del deposito di monete",
    204:'Osservazioni',
    205:'Copia indirizzo',
    206:"Copia riuscita",
    207: 'Ritorno',
    208:'Facoltativo',
    209:'Inserire il numero di monete da ricaricare',
    210:'Per favore un numero valido',
    211:'Inserire l\'indirizzo della valuta',
    212:'Carica uno screenshot del deposito di monete',
    213: "Indirizzo per il ritiro",
    214: "Importo del prelievo",
    215:'Saldo disponibile',
    216: 'Tutti',
    217:"Commissione di gestione",
    218:'Inserire la Importo di monete prelevate',
    219:'Inserire la password del fondo',
    220:"La password del fondo deve essere di 6 cifre",
    221:'Password predefinita',
    222:'Non hai ancora legato il portafoglio corrispondente! ',
    223:'Vai ad aggiungere',
    224: 'Invio riuscito',
    225:"Aggiungi portafoglio raccolta",
    226:'Indirizzo',
    227:'Inserire la rete',
    228:'Inserire l\'indirizzo',
    229: 'Rete',
    230:"Rete di prelievo",
    231:'La lunghezza dell\'indirizzo è il minimo 32 bit',
    232: "Trasferimento di fondi",
    233: 'Importo di trasferimento',
    234:'Inserire l\'importo del bonifico',
    235: "Equilibrio insufficiente",
    236:'Trasferibile',
    237: 'Direzione di scorrimento',
    238: 'Trasferimento',
    239: "Da",
    240: 'a',
    241: "Registri finanziari",
    242: "registro di deposito di monete",
    243: 'Registro di prelievo',
    244: "Registri di trasferimento",
    245: 'Importo',
    246: 'Stato',
    247:'Tempo creato',
    248:'Conferma ora',
    249: 'Non più',
    250:'Prezzo',
    253: "Moneta",
    254: 'Scambio',
    255:'Nessun dato ancora',
    256: 'Totale',
    257:'Alto',
    258: 'Basso',
    259: "Volume",
    260: 'Apri',
    261:'Ricevi',
    262: 'Importo',
    263: "Commercio di futures",
    264: 'rialzista',
    265: 'Ribassista',
    266:'Nome del prodotto',
    267:'Visualizza contratto',
    268:'Acquista importo',
    269:'Inserire l\'importo dell\'acquisto',
    270: "Commissione di transazione",
    271:'Acquisto minimo',
    272: 'Stato',
    273:"In attesa di consegna",
    274: 'Consegnato',
    275:'Ordine effettuato con successo',
    276:'Nome prodotto',
    277: "Profitti e perdite",
    278:"Prezzo di acquisto",
    279: 'Prezzo di consegna',
    280: 'Continuare a fare trading',
    283: "Profitto",
    284:'Conferma ordine',
    285:'Ordini di storia',
    286:"Ordini sospesi",
    287:'Tempo creato',
    288:'Compra',
    289:'Vendi',
    290:'Numero di operazioni',
    291:"Importo della transazione",
    292: "Contratto",
    293:'Tempi di consegna',
    294:'Prezzo di consegna',
    295:'secondi',
    296: "Minuti",
    297:'Ora',
    298: "Giorno",
    299:'Tempo rimanente',
    300:'Vedi di più',
    301:'Transazione valutaria',
    302:'Importo di vendita',
    303:'Inserire l\'importo di vendita',
    304: "Transazione in valuta Fiat",
    305:'Risorse',
    306:'Esci',
    307:'Registrato con successo',
    308:'Inserire una raccomandazione',
    309: 'Successo',
    310: 'Fallito',
    311: 'Conferma',
    312:'Metodo di pagamento',
    313: "Indirizzo per il ritiro",
    314:'Elimina',
    315: 'Operazione',
    316:'Il tuo portafoglio',
    317:'Sei sicuro di voler eliminare? ',
    318:'Cancellazione riuscita',
    319:"Autenticazione fallita",
    320: 'Ricertificazione',
    330: "Portafoglio di prelievo di risorse digitali",
    331: 'vincolare carta di credito, prelevare contanti in valuta fiat',
    332:'Inserire un indirizzo valido',
    333: 'Virtuale',
    334: 'Forex',
    335: "Metalli",
    1000: 'giorno',
    1001: 'In corso',
    1002: 'Chiuso',
    1003: "Regole",
    1004: 'Vantaggio del prodotto',
    1005: 'Salva come prendi',
    1006: 'Divide scaduto',
    1007: 'Bonus giornaliero',
    1008: "Interessi sui depositi in tempo reale",
    1009: 'Garanzia del capitale al 100%',
    1010: 'Reddito normale durante le vacanze',
    1011: "Riposa il giorno dopo",
    1012: "Vantaggio del prodotto",
    1013: `I membri depositano 200.000 USDT sulla piattaforma, scelgono un prodotto di gestione patrimoniale con un periodo di 14 giorni e un reddito giornaliero dello 0,5% e il reddito giornaliero è il seguente: 200.000*0,5%=1.000USDT Cioè, dopo 14 giorni, tu può ottenere 14.000 USDT di reddito e il reddito viene emesso giornalmente Deposita il capitale e verrà automaticamente restituito al tuo conto alla scadenza.`,
    1014: "Ordine di custodia",
    1015: 'Ospite',
    1016: 'Completato',
    1017: "Redento",
    1018: 'Nessun dato ancora',
    1019: 'Importo di acquisto',
    1020: 'Tasso di rendimento giornaliero',
    1021: 'FIDUCIA.6',
    1022: 'Tempo di pagamento',
    1023: 'Data di Rimborso',
    1024: 'Scaduto',
    1025: 'Recentemente (reddito giornaliero)',
    1026: 'Tempo di dividendo',
    1027: 'Ogni giorno',
    1028: 'Importo di deposito',
    1029: 'Reso alla scadenza',
    1030: 'Saldo disponibile',
    1031: "Reddito totale stimato",
    1032: 'Trasferimento di fondi',
    1033: 'Tutti',
    1034: 'Limite unico',
    1035: "Commissione di gestione",
    1036: 'Ho letto e acconsentito',
    1037: "Accordo di servizio di gestione finanziaria della piattaforma",
    1038: "Va bene",
    1039: "Sottoscrizione",
    1040: "Compra",
    1041: 'Vendi',
    1042: 'Bonifico bancario',
    1043: `Inserire l'importo`,
    1044: 'Categoria valuta Fiat',
    1045: 'Quantità venduta',
    1046: 'Massimo disponibile per la vendita',
    1047: 'Tutti',
    1048: 'Prezzo unitario di riferimento',
    1049: 'Il tuo metodo di pagamento',
    1050: "Nome banca",
    1051: 'Numero di conto bancario',
    1052: 'Nome',
    1053: 'Non hai vincolato il metodo di pagamento corrispondente e non può essere venduto. ',
    1054: `Registro dell'ordine`,
    1055: 'Filtro',
    1056: 'Tipo',
    1057: 'Stato',
    1058: 'Elaborazione',
    1059: 'Superato',
    1060: 'Fallito',
    1061: 'Quantità',
    1062: 'Successo',
    1063: `Esecuzione dell'attività`,
    1064: `Calcola i risultati di oggi`,
    1065: 'Reddito cumulato',
    1066: "Ordine di custodia",
    1067: 'Conto fiduciario non aperto',
    1068: `Apri un conto fiduciario di comunicazione, goditi un reddito passivo stabile e facilita l'apprezzamento delle risorse.`,
    1069: `Richiedere l'attivazione significa che l'hai letto e accettato`,
    1070: '"Contratto di servizio di gestione finanziaria della piattaforma"',
    1071: 'Richiedi attivazione',
    1072: 'Annulla',
    1073: 'Esecuzione...',
    1074: 'Redenzione',
    1075: 'Sei sicuro di volerlo riscattare? ',
    1076: "Il riscatto anticipato richiede il pagamento della liquidazione dei danni, che questa volta verrà detratta in base al tipo di gestione finanziaria",
    1077: ', La redenzione è confermata?',
    1078: 'Inviato',
    1079: 'Inserire il codice di verifica',
    1080: "Richiesta",
    1081: 'Profitto',
    1082: 'Tempo',
    1083: "Aggiungi metodo di pagamento in valuta legale",
    1084: "Stato dell'ordine",
    1085: 'Valuta',
    1086: 'Numero di valuta legale',
    1087: 'Numero di conto bancario',
    1088: "Metodo di riscossione del grasso",
    1089: 'Aggiungi metodo di raccolta',
    1090: 'Metodo di pagamento esistente',
    1091: 'valuta Fiat',
    1092: 'Codice Bancario',
    1093: 'Sei sicuro di voler eliminare il metodo di pagamento?',
    1094: 'Inserire il numero di conto bancario',
    1095: 'Inserire il nome della banca',
    1096: 'Inserire la valuta legale',
    1097: 'Per favore seleziona',
    1098: 'Inserisci il tuo nome',
    1099: 'Ramo',
    1100:'Illimitato',
    1101:'Non hai ancora verificato il tuo vero nome',
    1102: 'Libro bianco',
    1103: 'Le nostre caratteristiche',
    1104: 'Siamo al sicuro',
    1105: 'Siamo potenti',
    1106: 'Miglior supporto',
    1107: 'Siamo globali',
    1108: 'Come funziona',
    1109: "Utenti in più di 20 paesi e regioni utilizzano la nostra piattaforma e sono ampiamente riconosciuti",
    1110: 'Crittografia dati avanzata',
    1111: 'Ricca varietà di criptovalute',
    1112: '90+ criptovalute elencate',
    1113: "I dati delle tue transazioni sono protetti tramite crittografia end-to-end, garantendo che solo tu abbia accesso alle tue informazioni personali.",
    1114: '43 miliardi',
    1115: 'Volume di scambio 24 ore',
    1116: '30 milioni',
    1117: 'Utenti registrati che si fidano di noi',
    1118: 'Commissioni di transazione più basse',
    1119: 'Scarica',
    1120:'Nome istituto finanziario',
    1121:'nome del ramo',
    1122:'numero sede',
    1123:'Numero di conto',
    1124:'Nome destinatario',
    1125: 'Kinjo',


    1148:'La piattaforma di criptovaluta più affidabile',
    1149:'Ecco i motivi per cui dovresti scegliere noi',
    1150:'Siamo al sicuro',
    1151:'Tecnologia imparziale per la privacy e la riservatezza a prova di conoscenza zero per proteggere la privacy degli utenti, firme multiple, architettura a strati e altri progetti di sicurezza',
    1152:'Miglior supporto',
    1153:'24 * 7 ore in qualsiasi condizione atmosferica, modalità operativa a tempo pieno, il servizio clienti risponderà alle tue domande il prima possibile',
    1154:'Siamo globali',
    1155:'Gli utenti in più di 20 paesi e regioni utilizzano la nostra piattaforma e sono ampiamente riconosciuti',
    1156:'Siamo potenti',
    1157:'La piattaforma di trading di asset digitali più popolare, l accesso ai più potenti e convenienti strumenti di analisi tecnica e trading sul mercato',
    1158:'Ricca varietà di criptovalute',
    1159:' Oltre 90 criptovalute elencate ',
    1160:'Crittografia avanzata dei dati',
    1161:'I tuoi dati di transazione sono protetti tramite crittografia end-to-end, assicurando che solo tu abbia accesso alle tue informazioni personali.',
    1162:'Supportiamo una varietà delle valute digitali più popolari',
    1163:'Molti paesi hanno stabilito una transazione localizzata',
    1164:'Usa la valuta legale per acquistare risorse digitali ed entra rapidamente nel mondo delle risorse digitali',
    1165:'Fai trading per andare ovunque in qualsiasi momento',
    1166:'Scansionami per scaricare',
    1167:'Tassi di cambio',
    1168:'Acquista e vendi oltre 250 criptovalute con tassi di cambio caricati di recente',
    1169:'Rimani aggiornato con la nostra app e il client desktop',
    1170:'Nome',
    1171:'Prezzo',
    1172:'Modificare',
    1173:'Grafico',
    1174:'Commercio',
    1175:'Dettaglio',
    1176:'Erano',
    1177:'Sicuro',
    1178:'abbiamo',
    1179:'Global',
    1180:'Potente',
    1181:'Ricca varietà di',
    1182:'Criptovaluta',
    1183:'Dati avanzati',
    1184:'Crittografia',
    1185:'pronto per iniziare',
    1186:'Siamo la piattaforma di trading di asset digitali leader a livello mondiale.',
    1187:'Siamo la piattaforma di trading di asset digitali leader al mondo. Ci sforziamo costantemente di migliorare le funzionalità di sicurezza per garantire la sicurezza delle risorse digitali dei nostri clienti. Il nostro modello è portare i progetti dei clienti con un valore sostenibile.',

    1188: 'Fornitura totale',
    1189: '24 * 7 per tutte le stagioni, modalità operativa a tempo pieno, il servizio clienti risponderà alle domande pertinenti per te il prima possibile',
    1190: 'Siamo globali',
    1191: 'Siamo forti',
    1192: 'La piattaforma di trading di asset digitali più popolare, ottieni l`analisi tecnica e gli strumenti di trading più potenti e convenienti sul mercato.',
    1193: 'Ampia varietà di criptovalute',
    1194: 'I dati delle tue transazioni sono protetti con la crittografia end-to-end, assicurando che solo tu possa accedere alle tue informazioni personali.',
    1195: 'Commissione minima di transazione',
    1196: 'Fidati dei nostri utenti registrati',
    1197: 'Volume di scambio di 24 ore',
    1198: '+42 miliardi',
    1199: '+30 milioni',
    1200: 'Diversi paesi hanno istituito centri di servizi di transazione localizzati per creare un ecosistema blockchain che integri più moduli aziendali',
    1201: 'Usa la valuta fiat per acquistare risorse digitali ed entrare rapidamente nel mondo delle risorse digitali',
    1202: 'Download APP',
    1203: 'Il nostro vantaggio',
    1204: 'Informazioni dettagliate su valuta e valuta, fornendo valuta, contratti, transazioni legali in valuta e altri metodi di transazione, mercato professionale e profondità.',
    1205: 'Licenza regolamentare'
}