import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
export default {
    ...zhLocale,
    1127: '下载',
    1128: '登陆',
    1130: '币种',
    1131: '价格',
    1132: '改变',
    1133: '图表',
    1134: '详情',
    1135: '立即创建您的加密货币投资组合',
    1136: '{appName}具有多种功能，使其成为开始交易的最佳场所',
    1137: '管理您的投资组合',
    1138: '买卖流行的数字货币，在一个地方跟踪它们',
    1139: '经常性购买',
    1140: '通过每天、每周或每月安排购买来慢慢投资加密货币',
    1141: '保险库保护',
    1142: '为了增加安全性，请将您的资金存放在保险库中，并延迟提款',
    1143: '移动应用',
    1144: '使用适用于 Android 或 iOS 的 {appName} 应用程序保持市场领先地位',
    1145: '虽然在异国货币对上进行杠杆交易很棒，但拥有一个充满 meme 和 alpha 的 Discord 服务器会更好',
    1146: '联系我们',

    11081: '精英交易平台：保持交易快速、稳定、高效',
    11031: '支持多种PC和移动操作系统',
    1126: '交易平台',
    11261: '最受欢迎的外汇交易平台',
    11191: '随时随地开启交易',
    nav0: '首页',
    nav1: '行情',
    nav2: '币币交易',
    nav3: '期货交易',
    nav4: '理财',
    nav5: '条款说明',
    nav6: '用户协议',
    nav7: '隐私条款',
    nav8: '法律申明',
    nav9: '交易规范',
    nav10: '新手帮助',
    nav11: '常见问题',
    nav12: '交易费率',
    nav13: '关于我们',
    nav14: '服务',
    operate: '操作',
    homeTitle_1_1: '全球领先的数字资产交易平台',
    homeTitle_1_2: '最受欢迎的数字资产交易平台，获得市场上最强大，最便捷的技术分析和交易工具。',
    register: '立即注册',
    nowtrans: '立即交易',
    logIn: '登录',
    whats: 'WhatsApp',
    service: '在线客服',
    home_1_3: '贵金属，差价合约平台',
    home_2_1: '客户资金安全的存放在全球AA级银行隔离信托基金',
    home_2_2: '更低的点差更稳定的服务器',
    footer1: `风险提示: 公司提供的金融产品包括差价合约（'CFDs'）和其他复杂的金融产品。交易差价合约具有很高的风险，因为杠杆可以同时对您有利和不利。因此，差价合约可能并不适合所有投资者，因为有可能会损失您所有的投资本金。您永远不应投资承受不起损失的资金。在交易提供的复杂金融产品之前，请确保了解所涉及的风险。您被授予有限的非排他性非转让权，仅出于与网站上提供的服务有关的个人和非商业目的使用本网站上提供的IP。 `,
    1: '产品名称',
    2: '预期日化收益率',
    3: '产品期限',
    4: '起投金额',
    5: '操作',
    6: '注册&购买',
    7: '余币理财',
    8: '灵活理财，天天有收益',
    9: '关于我们',
    10: '用户协议',
    11: '隐私条款',
    12: '法律申明',
    13: '交易规则',
    14: '条款说明',
    15: '服务',
    16: '新手帮助',
    17: '常见问题',
    18: '交易费率',
    19: '数字货币秒合约交易系统',
    20: '快速、便捷、无分摊、风险可控',
    21: '系统介绍',
    22: '随着数字货币期货的快速发展,广大用户持有资产越来越多，虚拟货币的交易方式也变的多种多样。用户也越来越追求快速，高效的交易结算方式，为此，秒合约也受到了 极大的推崇。互融云推出的秒合约是一款用于虚拟货币合约交易的以分为单位进行结算的交易系统。系统支持市价交易和限价交易；用户可买入看涨、看跌期权，资金投入量小、门槛低，结算周期短，分为1分钟、2分钟、3分钟等，系统可自主配置。系统具有严密的风控体系，设置最高、最低涨跌幅，超出限制，赔率自动归零。 ',
    23: 'K线来源',
    24: '交易主要依靠外部交易所大盘行情进行交易',
    25: '支持币种',
    251: '入金方式',
    252: '充币',
    26: '通过钱包地址进行充值',
    27: '通过C2C交易进行购买',
    28: '满足平台发放币的需求',
    281: '通过OTC交易进行购买，',
    282: '平台无需承担货币浮动风险',
    29: '合约模型设计',
    30: '交易方式',
    31: '结算时间设置',
    32: '交易方向',
    33: '交易控制',
    34: '风险控制',
    35: '买入额度限制',
    36: '防止客户大规模亏损',
    38: '设置截止下单时间',
    39: '保证足够的清算时间进行开奖',
    40: '设置涨幅线、跌幅线',
    41: '触发赔率清零，防止单边交易',
    42: '避免大规模亏损',
    43: '一键平仓按钮',
    44: '客户可在极端情况下一键平仓按钮',
    46: '服务监控',
    47: '服务全面监控，保障服务故障及时响应，及时发起故障邮件通知',
    48: '服务支持',
    49: '我们用心走近客户，我们热心、专心、耐心、细心，我们拥有专业的软件研发团队、严谨的平台监控管理、专业的一站式服务团队。 ',
    50: '一, 选择交易对',
    51: '二, 查看价格',
    52: '看市场成交价格，选择合适价格下单。 ',
    53: '三, 填写订单后买入',
    54: '选择需要购买的交易对（币兑币交易就是指用一种数字资产去兑换另一种数字资产）',
    55: '填写买入价和买入量，等待市场成交。 ',
    57: '秒懂币币交易',
    58: '安全可靠 交易迅速',
    59: '交易行情',
    60: '现货',
    601: '向全球投资者提供先进的数字资产、差价合约和商品交易平台',
    61: '什么是现货？ ',
    62: '现货是对于期货而言。现货和期货大同小异，主要区别在于杠杆及保证金、交易交割时间等差异',
    63: '期货',
    64: '什么是期货?',
    65: '期货合约，简称期货，是一种跨越时间的交易方式。买卖双方透过签订合约，同意按指定的时间、价格与其他交易条件，交收指定数量的现货。 ',
    66: '信托理财',
    67: '什么是信托理财?',
    68: '去中心化金融是一种基于区块链的金融，它不依赖券商、交易所或银行等金融机构提供金融工具，而是利用区块链上的智能合约进行金融活动',
    69: 'C2C',
    70: '什么是C2C',
    71: 'C2C，也称“私对私”是一种交易的方式，它是指个人对个人的交易形式',
    72: '一站式交易服务',
    73: '名称',
    74: '价格',
    75: '24H涨跌',
    76: '24H成交量',
    77: '行情',
    78: '稳定 安全 聚合',
    79: '金融级安全防护',
    80: '无偏性零知识证明隐私保密技术保护用户隐私，多种签名、分层架构等安全设计。 ',
    81: '高性能撮合引擎',
    82: '采用先进的分布式集群架构及微服务开发方式 。支持超过300万/秒交易并发。 ',
    83: '24小时不间断服务',
    84: '客服七天24小时全天在线，为你解答各种问题。 ',
    85: '随时随地 开启交易',
    86: '全新改版，随时满足各种场景的交易需求',
    87: '24*7小时全天候、全日制运营模式，客服第一时间为您解答相关咨询。 ',
    88: '交易对',
    89: '最新价',
    90: '涨跌幅',
    91: '最高价',
    92: '最低价',
    93: '成交量',
    94: '登录',
    95: '注册',
    fee: {
        1: '交易费率',
        2: '币币交易手续费',
        3: '合约交易手续费'
    },
    law: {
        1: '法律申明',
        2: '第一条',
        3: '本平台致力于为用户创建一个合规、安全、可靠的数字资产交易环境，禁止用户使用本网站从事洗钱、走私、商业贿赂、恶意操纵市场等一切非法交易活动或违法行为。为此我们尽最大努力制定严格的反洗钱反恐怖方案及采取有效的安全风险控制措施，以最大限度地保障用户的合法权益，并使平台自身维持着一个良好的品牌声誉',
        4: '第二条',
        5: '当有权机关出示相应的调查文件要求本网站配合对指定用户进行调查时， 或对用户账户采取查封、冻结或者划转等措施时，本网站将在核查司法文件的有效性和真实性后，按照经平台判定的有权机关的要求协助提供相应的用户数据，或进行相应的操作，因此造成的用户隐私泄露、账户不能操作及由此给用户所造成的其他损失等，本网站不承担任何责任。 ',
        6: '第三条',
        7: '凡使用本网站服务的用户，在交易前均应知悉:数字资产不以任何政府或中央银行为后盾支持，且数字资产价格波动大，持有或使用数字资产具有较高的风险。在作出交易决策前，您应理性审慎自身的财务状况以判断是否适合您',
        8: '第四条',
        9: '凡以任何方式登录本网站或直接、间接使用本网站服务者，视为自愿接受本网站声明的约束。 '
    },
    rules: {
        1: '交易规则',
        2: '创建订单后，买方应当：',
        3: '1、 在订单规定的付款时间内完成支付，再点击“我已付款”；',
        4: '2、 使用本人实名付款方式、按订单金额及订单指定的收款账户以实时到账方式完成订单支付；',
        5: '3、 选择非实时到账方式支付订单的，必须事先获得卖方同意',
        6: '4、 支付订单不得备注“数字货币、BTC、比特币”等信息；',
        7: '卖方应遵循的原则',
        8: '生成订单后，卖方应当',
        9: '1、 提供经平台认证的收款账户；',
        10: '2、 收到买方全额付款后，在订单规定时间内完成放行；',
        11: '3、 非经买方同意，不得要求买家/客服取消订单；',
        12: '4、 非经买方同意，不得对已创建的订单价格提出异议；'
    },
    help: {
        1: '新手帮助',
        2: '限价单',
        3: '一种以指定价格或更优价格买入/卖出的订单。 ',
        4: '市价单',

        5: '一种以当前市价立即执行买入或卖出的订单。 ',
        6: '限价止损单',
        7: '一种在市场达到特定价格后，以设定价格卖出或平仓的订单，用于控制损失。 ',
        8: '限价止盈单',
        9: '类似于限价止损单，同样在市场达到特定价格后，以设定价格卖出或平仓的订单，用于控制盈利。 ',
        10: '市价止损单',
        11: '一种在市场达到特定价格后，以市价卖出或平仓的订单，用于控制损失。 ',
        12: '市价止盈单',
        13: '类似于市价止损单，同样在市场达到特定价格后，以市价卖出或平仓的订单，用于控制盈利。 ',
        14: '什么是开仓保证金？ ',
        15: '保证金交易即你只需要支付一定比例的保证金，就可以买卖合约，根据选择的杠杆倍数，保证金比例为合约价值的更少倍数比例。他能放大您的收益，同时也可能使您遭受额外的风险。 ',
        16: '什么是爆仓什么是穿仓？ ',
        17: '当您账户的剩余资金小于交易合约所需要的浮动盈亏时，您的仓位会被交易所强行平仓，又称爆仓。由于平仓时的价格，是根据市场价所决定的，当成交价格极为不利时，可能您的资金不足以支付交易产生亏损，导致您的账户为负值，这种情况称为穿仓。 '
    },
    96: '选择交易对',
    97: '查看价格',
    98: '填写订单后购买',
    99: '账户登录',
    100: '欢迎回来！使用您的电子邮箱或电话号码登录',
    101: '邮箱',
    102: '手机',
    103: '忘记密码？',
    104: '立即注册',
    105: '密码',
    106: '请输入手机号码',
    107: '手机号格式错误',
    108: '请输入邮箱',
    109: '邮箱格式错误',
    110: '请输入密码',
    111: '密码最少6位',
    112: '密码最多32位',
    113: '语言和地区',
    114: '创建{appName}账户',
    115: '使用您的电子邮箱或手机号进行注册',
    116: '推荐码',
    '116x': '区域识别码',
    117: '同意',
    118: '和',
    119: '用户协议',
    120: '隐私政策',
    121: '注册过？',
    122: '验证码',
    123: '发送',
    124: '请输入验证码',
    125: '验证码必须为6位数字',
    126: '重置密码',
    127: '确定',
    128: '使用您的电子邮箱或手机重置登录密码',
    129: '实名认证',
    130: '完成实名认证有助于保护账户安全，提高提现额度及交易权限',
    131: '去认证',
    132: '身份验证',
    133: '用于登录、提币、找回密码、修改安全设置',
    134: '已验证',
    135: '密码管理',
    136: '登录密码',
    137: '资金密码',
    138: '用于保护账户安全',
    139: '用于保护资金安全',
    140: '个人设置',
    141: '颜色偏好',
    142: '设置',
    143: '修改',
    144: '设定K线图、买涨、买跌的颜色',
    145: '计价方式',
    146: '账户资产的计价单位，',
    147: '默认',
    148: '绿涨红跌',
    149: '红涨绿跌',
    150: '修改登录密码',
    151: '修改资金密码',
    152: '旧密码',
    153: '新密码',
    154: '请输入旧密码',
    155: '新手机号',
    156: '新邮箱',
    157: '绑定手机号',
    158: '绑定邮箱',
    159: '修改手机号',
    160: '修改邮箱',
    161: '已认证',
    162: '设置昵称',
    163: '请输入昵称',
    164: '取消',
    165: '国家',
    166: '名',
    167: '姓',
    168: '证件类型',
    169: '证件号码',
    170: '请选择国家',
    171: '请输入名',
    172: '请输入姓',
    173: '请输入证件号码',
    174: '请上传证件正面照片',
    175: '请上传证件反面照片',
    176: '请上传手持证件照片',
    177: '提交',
    178: '认证中',
    179: '身份证',
    1790: '驾驶证',
    180: '护照',
    1800: 'SSN',
    181: '下一步',
    182: '上传证件照片，完成认证',
    183: '上传失败',
    184: '图片格式错误',
    185: '图片过大',
    186: '加载中',
    187: '资产总览',
    188: '划转',
    189: '提币',
    190: '充币',
    191: '财务记录',
    192: '冻结',
    193: '可用',
    194: '总额',
    195: '隐藏小额币种',
    196: '币种',
    197: 'USDT估值',
    198: '搜索',
    199: '充值网络',
    200: '充币地址',
    201: '出币地址',
    202: '充币数量',
    203: '充币截图',
    204: '备注',
    205: '复制地址',
    206: '复制成功',
    207: '返回',
    208: '选填',
    209: '请输入充币数量',
    210: '请有效的数字',
    211: '请输入出币地址',
    212: '请上传充币截图',
    213: '提币地址',
    214: '提币数量',
    215: '可用余额',
    216: '全部',
    217: '手续费',
    218: '请输入提币数量',
    219: '请输入资金密码',
    220: '资金密码必须是6位数字',
    221: '默认密码',
    222: '您还未绑定对应钱包！',
    223: '去添加',
    224: '提交成功',
    225: '添加收款钱包',
    226: '地址',
    227: '请输入网络',
    228: '请输入地址',
    229: '网络',
    230: '提币网络',
    231: '地址长度最低32位',
    232: '资金划转',
    233: '划转数量',
    234: '请输入划转数量',
    235: '余额不足',
    236: '可划转',
    237: '划转方向',
    238: '划转',
    239: '从',
    240: '至',
    241: '财务记录',
    242: '充币记录',
    243: '提币记录',
    244: '划转记录',
    245: '数量',
    246: '状态',
    247: '创建时间',
    248: '确认时间',
    249: '没有更多了',
    250: '价格',
    253: '交易对',
    254: '涨跌幅',
    255: '暂无数据',
    256: '累计',
    257: '高',
    258: '低',
    259: '额',
    260: '开',
    261: '收',
    262: '量',
    263: '期货交易',
    264: '看涨',
    265: '看跌',
    266: '产品名称',
    267: '查看合约',
    268: '买入金额',
    269: '请输入买入金额',
    270: '交易手续费',
    271: '最少买入',
    272: '状态',
    273: '待交割',
    274: '已交割',
    275: '下单成功',
    276: '产品名称',
    277: '盈亏',
    278: '买入价格',
    279: '交割价格',
    280: '继续交易',
    283: '收益',
    284: '确认下单',
    285: '历史订单',
    286: '在持订单',
    287: '创建时间',
    288: '买入',
    289: '卖出',
    290: '成交数量',
    291: '成交金额',
    292: '合约',
    293: '交割时间',
    294: '交割价格',
    295: '秒',
    296: '分',
    297: '时',
    298: '天',
    299: '剩余时间',
    300: '查看更多',
    301: '币币交易',
    302: '卖出金额',
    303: '请输入卖出金额',
    304: '法币交易',
    305: '资产',
    306: '退出登录',
    307: '注册成功',
    308: '请输入推荐码',
    '308x': '请输入区域识别码',
    309: '成功',
    310: '失败',
    311: '确认中',
    312: '收款方式',
    313: '提币地址',
    314: '删除',
    315: '操作',
    316: '您的钱包',
    317: '确定删除？',
    318: '删除成功',
    319: '认证未通过',
    320: '重新认证',
    330: '数字资产提现钱包',
    331: '绑定银行卡，提现到法币',
    332: '请输入有效的地址',
    333: '虚拟币',
    334: '外汇',
    335: '贵金属',
    1000: '天',
    1001: '进行中',
    1002: '已关闭',
    1003: '规则',
    1004: '产品优势',
    1005: '随存随取',
    1006: '派息过期',
    1007: '天天分红',
    1008: '活存利息',
    1009: '100%资金保证',
    1010: '节假日正常收益',
    1011: '次日起息',
    1012: '产品优势',
    1013: '会员在平台存入了200000USDT，选择周期为14天，日收益为0.5%的理财产品，则每天收益如下：200000*0.5%=1000USDT 即：14天后可以获得14000USDT的收益，收益每日下发，存入本金，到期满后，自动返还至您的帐户。',
    1014: '托管订单',
    1015: '托管中',
    1016: '已完成',
    1017: '已赎回',
    1018: '暂无数据',
    1019: '购买金额',
    1020: '日收益率',
    1021: 'TRUST.6',
    1022: '支付时间',
    1023: '赎回日期',
    1024: '过期',
    1025: '近日(日收益)',
    1026: '派息时间',
    1027: '每天',
    1028: '托管金额',
    1029: '到期返还',
    1030: '可用余额',
    1031: '预计总收益',
    1032: '资金划转',
    1033: '全部',
    1034: '单笔限额',
    1035: '手续费',
    1036: '我已阅读并同意',
    1037: '平台理财服务协议',
    1038: '确定',
    1039: '申购',
    1040: '购买',
    1041: '出售',
    1042: '电汇转帐',
    1043: '请输入金额',
    1044: '法币类别',
    1045: '出售数量',
    1046: '最大可售',
    1047: '全部',
    1048: '参考单价',
    1049: '您的收款方式',
    1050: '银行名称',
    1051: '银行帐号',
    1052: '姓名',
    1053: '您还没有绑定相应的收款方式，无法出售。 ',
    1054: '订单记录',
    1055: '筛选',
    1056: '类型',
    1057: '状态',
    1058: '处理中',
    1059: '通过',
    1060: '失败',
    1061: '数量',
    1062: '成功',
    1063: '正在执行任务',
    1064: '计算今日成果',
    1065: '累计收益',
    1066: '托管中订单',
    1067: '信托账户未开通',
    1068: '开通信托账户，享受稳定被动收益，让资产增值更加简单。 ',
    1069: '申请开通即表示您已阅读并同意',
    1070: '《平台理财服务协议》',
    1071: '申请开通',
    1072: '取消',
    1073: '执行中...',
    1074: '赎回',
    1075: '确定要赎回吗？',
    1076: '提前赎回需要支付违约金，根据您的理财类型，本次将扣除',
    1077: '，确认赎回吗？',
    1078: '已发送',
    1079: '请输入验证码',
    1080: '提示',
    1081: '收益',
    1082: '时间',
    1083: '添加法币收款方式',
    1084: '订单状态',
    1085: '币种',
    1086: '法币数量',
    1087: '银行账号',
    1088: '法币收款方式',
    1089: '添加收款方式',
    1090: '现有收款方式',
    1091: '法币',
    1092: '银行编码',
    1093: '确定删除收款方式？ ',
    1094: '请输入银行帐号',
    1095: '请输入银行名称',
    1096: '请输入法币',
    1097: '请选择',
    1098: '请输入姓名',
    1099: '支行',
    1100: '无限制',
    1101: '您尚未实名认证',
    1102: '白皮书',
    1103: '我们的优势',
    1104: '安全可靠',
    1105: '我们很强大',
    1106: '最佳支持',
    1107: '我们是全球性的',
    1108: '它是如何工作的',
    1109: '20多个国家和地区的用户在使用我们的平台并得到广泛认可',
    1110: '高级数据加密',
    1111: '种类繁多的加密货币',
    1112: '列出了 90 多种加密货币',
    1113: '您的交易数据通过端到端加密得到保护，确保只有您可以访问您的个人信息。',
    1114: '430 亿',
    1115: '24 小时交易量',
    1116: '三千万',
    1117: '信任我们的注册用户',
    1118: '最低交易费用',
    1119: '下载',
    1120: '金融机构名称',
    1121: '分支名称',
    1122: '分公司号码',
    1123: '帐号',
    1124: '收件人姓名',
    1125: '金城',

    1148: '最值得信赖的加密货币平台',
    1149: '以下是您应该选择我们的理由',
    1150: '我们很安全',
    1151: '无偏的零知识证明隐私保密技术，保护用户隐私，多重签名，分层架构等安全设计 ',
    1152: '最佳支持',
    1153: '24*7小时全天候、全时运营模式，客服会第一时间回复您的相关咨询',
    1154: '我们是全球性的',
    1155: '20多个国家和地区的用户在使用我们的平台并得到广泛认可',
    1156: '我们很强大',
    1157: 'T最受欢迎的数字资产交易平台，接入市面上最强大便捷的技术分析和交易工具',
    1158: '丰富多样的加密货币',
    1159: '列出了 90 多种加密货币',
    1160: '高级数据加密',
    1161: '您的交易数据通过端到端加密得到保护，确保只有您可以访问您的个人信息。 ',
    1162: '我们支持多种最流行的数字货币',
    1163: '许多国家已经建立了本地化交易',
    1164: '使用法币购买数字资产，快速进入数字资产世界',
    1165: '随时随地进行交易',
    1166: '扫我下载',
    1167: '汇率',
    1168: '通过最近上传的汇率买卖 250 多种加密货币',
    1169: '使用我们的应用程序和桌面客户端随时了解情况',
    1170: '姓名',
    1171: '价格',
    1172: '改变',
    1173: '图表',
    1174: '贸易',
    1175: '细节',
    1176: '我们是',
    1177: '安全的',
    1178: '我们有',
    1179: '全球的',
    1180: '强大的',
    1181: '丰富的品种',
    1182: '加密货币',
    1183: '高级数据',
    1184: '加密',
    1185: '准备开始',
    1186: '我们是全球领先的数字资产交易平台。 ',
    1187: '我们是全球领先的数字资产交易平台。 我们不断努力改进安全功能，以确保客户数字资产的安全。 我们的模式是为客户提供具有可持续价值的作品。',

    1188: '总供应量',
    1189: '24*7全天候、全时运营模式，客服第一时间为您解答相关咨询',
    1190: '我们是全球性的',
    1191: '我们是强大的',
    1192: '最受欢迎的数字资产交易平台，获得市场上最强大、最便捷的技术分析和交易工具。',
    1193: '种类繁多的加密货币',
    1194: '您的交易数据受到端到端加密的保护，确保只有您可以访问您的个人信息。',
    1195: '最低交易费用',
    1196: '相信我们的注册用户',
    1197: '24小时交易量',
    1198: '+420 亿',
    1199: '+3000 万',
    1200: '多个国家设立本地化交易服务中心，打造融合多种业态的区块链生态系统 ',
    1201: '使用法币购买数字资产，快速进入数字资产世界',
    1202: 'APP下载',
    1203: '我们的优势',
    1204: '丰富的币种和币种信息，提供币种、合约、法币交易等多种交易方式，专业的行情和深度。',
    1205: '监管牌照'
}