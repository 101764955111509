import koLocale from 'element-ui/lib/locale/lang/ko'
export default {
    ...koLocale,
    1127: '다운로드',
    1128: '로그인',
    1130: '통화',
    1131: '가격',
    1132: '변경',
    1133: '그래프',
    1134: '세부 사항',
    1135: '지금 암호화폐 포트폴리오를 만드십시오',
    1136: '{appName}은(는) 거래를 시작하기에 가장 좋은 곳으로 만드는 여러 기능을 가지고 있습니다.',
    1137: '포트폴리오 관리',
    1138: '인기 있는 디지털 통화를 사고 팔고 한 곳에서 모두 추적',
    1139: '반복 구매',
    1140: '일별, 주별 또는 월별 구매를 정리하여 천천히 암호화폐에 투자',
    1141: '금고 보호',
    1142: '보안 강화를 위해 자금을 금고에 보관하고 인출을 연기하세요',
    1143: '모바일 앱',
    1144: 'Android 또는 iOS용 {appName} 앱으로 시장을 선도하세요',
    1145: '이그조틱 쌍에 대한 레버리지 거래도 훌륭하지만 밈과 알파로 가득 찬 Discord 서버를 갖는 것이 훨씬 낫습니다',
    1146: '문의하기',

    11081: '엘리트 거래 플랫폼: 거래를 빠르고 안정적이며 효율적으로 유지',
    11031: '여러 PC 및 모바일 운영 체제 지원',
    1126: '거래 플랫폼',
    11261: '가장 인기 있는 외환 거래 플랫폼',
    11191: '언제 어디서나 거래 열기',
    nav0: '홈페이지',
    nav1: '따옴표',
    nav2: '코인 거래',
    nav3: '계약 거래',
    nav4: '재무 관리',
    nav5: '약관 설명',
    nav6: '이용약관',
    nav7: '개인정보 보호정책',
    nav8: '법적 진술',
    nav9: '거래 사양',
    nav10: '초보자 도움말',
    nav11: '자주 묻는 질문',
    nav12: '거래 수수료율',
    nav13: '회사 소개',
    nav14: '서비스',
    operate: '작업',
    homeTitle_1_1: '세계 최고의 디지털 자산 거래 플랫폼',
    homeTitle_1_2: '가장 인기 있는 디지털 자산 거래 플랫폼으로 시장에서 가장 강력하고 편리한 기술 분석 및 거래 도구에 액세스할 수 있습니다. ',
    register: '지금 등록',
    nowtrans: '즉시 거래',
    logIn: '로그인',
    whats: 'WhatsApp',
    service: '고객 서비스',
    home_1_2: '캐로드증권은 믿을 수 있는 외환',
    home_1_3: '귀금속, CFD 플랫폼',
    home_2_1: '고객 자금은 글로벌 AA등급 은행 분리 신탁 펀드에 안전하게 보관',
    home_2_2: '더 낮은 스프레드와 더 안정적인 서버',
    footer1: `위험 경고: 회사에서 제공하는 금융 상품에는 차액 계약('CFD') 및 기타 복합 금융 상품이 포함됩니다. CFD 거래는 높은 수준의 위험을 수반합니다. 레버리지는 동시에 당신에게 좋을 수도 있고 나쁠 수도 있기 때문입니다. 따라서 CFD는 모든 투자 원금을 잃을 수 있으므로 모든 투자자에게 적합하지 않을 수 있습니다. 잃을 여유가 없는 돈을 투자해서는 안 됩니다. 제공된 복잡한 금융 상품을 거래하기 전에 수반되는 위험을 반드시 이해하시기 바랍니다. 귀하는 이 웹사이트에서 제공되는 서비스와 관련된 개인적 및 비상업적 목적으로만 이 웹사이트에서 제공된 IP를 사용할 수 있는 제한적이고 비독점적이고 양도 불가능한 권리를 부여받습니다. `,
    footer2: '이 웹사이트의 정보는 웹사이트에 설명된 법률 및 규정과 다른 국가 또는 관할권의 거주자를 대상으로 하지 않습니다. Carrod Securities Co Ltd는 재정 고문이 아니며 일반적인 조언만 제공됩니다. .증거금 거래의 취득, 보유, 매도에 대해 어떠한 의견이나 제안도 하지 않습니다.',
    1: '제품명',
    2: '일일 기대수익률',
    3: '제품 마감',
    4: '초기 투자 금액',
    5: '작전',
    6: '구매',
    7: '잔액관리',
    8: '유연한 재무 관리, 매일 이익',
    9: '우리 소개',
    10: '이용계약',
    11: '개인정보 보호정책',
    12: '법적 진술',
    13: '거래 규칙',
    14: '약관 설명',
    15: '서비스',
    16: '초보자 도움말',
    17: 'FAQ',
    18: '거래 수수료율',
    19: '디지털 화폐 2차 계약 거래 시스템',
    20: '빠르고, 편리하고, 배분하지 않고, 위험을 제어할 수 있습니다.',
    21: '시스템 소개',
    22: '디지털 화폐 선물의 급속한 발전으로 대다수의 사용자가 점점 더 많은 자산을 보유하고 있으며 가상 화폐의 거래 방법도 다양해졌습니다. 사용자들은 점점 더 빠르고 효율적인 거래 정산 방식을 추구하고 있어 2차 계약도 높은 평가를 받고 있다. 뮤추얼 클라우드가 출시한 두 번째 계약은 가상 화폐 계약을 센트 단위로 결제하는 거래 시스템입니다. 시스템은 시장 가격 거래 및 제한 가격 거래를 지원합니다. 사용자는 소액 자본 투자, 낮은 임계값 및 짧은 결제 주기로 1분, 2분, 3분 등으로 분할된 콜 및 풋 옵션을 구매할 수 있으며 시스템은 다음을 수행할 수 있습니다. 독립적으로 구성할 수 있습니다. 시스템에는 가장 높은 변동과 가장 낮은 변동이 설정된 엄격한 위험 제어 시스템이 있으며 한도를 초과하면 확률이 자동으로 0으로 재설정됩니다. ',
    23: 'K 라인 소스',
    24: '거래는 주로 거래를 위한 외부 거래소의 시장 호가에 의존',
    25: '통화 지원',
    251: '입금 방식',
    252: '예금 동전',
    26: '지갑 주소로 충전',
    27: 'C2C 거래를 통한 구매',
    28: '코인 발행 플랫폼의 요구 사항 충족',
    281: '장외거래를 통한 구매',
    282: '플랫폼은 통화 변동 위험을 감수할 필요가 없습니다',
    29: '계약 모델 디자인',
    30: '거래 방법',
    31: '정산시간 설정',
    32: '무역 방향',
    33: '거래 통제',
    34: '위험 통제',
    35: '구매 한도',
    36: '고객의 대규모 손실 방지',
    38: '주문 마감일 설정',
    39: '추첨을 위한 충분한 청산 시간 보장',
    40: '설정 및 다운 라인',
    41: '일방적 거래를 방지하기 위해 확률을 0으로 트리거',
    42: '대규모 손실 방지',
    43: '원 클릭 닫기 버튼',
    44: '고객은 버튼을 클릭하여 극단적인 상황에서 포지션을 닫을 수 있습니다',
    46: '서비스 모니터링',
    47: '서비스 실패에 대한 시기적절한 응답을 보장하고 적시에 실패 이메일 알림을 시작하기 위한 서비스의 포괄적인 모니터링',
    48: '서비스 지원',
    49: '우리는 마음으로 고객에게 접근하고, 열정적이고, 세심하고, 참을성 있고, 신중합니다.우리는 전문 소프트웨어 R&D 팀, 엄격한 플랫폼 모니터링 및 관리, 전문 원스톱 서비스 팀을 보유하고 있습니다. ',
    50: '하나, 거래 쌍 선택',
    51: '둘, 가격 확인',
    52: '시장 거래 가격을보고 올바른 가격을 선택하여 주문하십시오. ',
    53: '셋, 주문서 작성 후 구매',
    54: '구매해야 하는 거래 쌍을 선택하십시오(통화 대 통화 거래는 하나의 디지털 자산을 사용하여 다른 디지털 자산과 교환하는 것을 말합니다)',
    55: '구매금액과 구매수량을 입력하고 시장거래를 기다립니다. ',
    57: '몇 초 만에 통화 거래 이해',
    58: '안전하고 신뢰할 수 있는 빠른 거래',
    59: '거래 시세',
    60: '재고 있음',
    601: '글로벌 투자자에게 고급 디지털 자산, CFD 및 상품 거래 플랫폼 제공',
    61: '그 자리는 무엇입니까? ',
    62: '현물은 선물용입니다. 현물과 선물은 유사하며 주요 차이점은 레버리지와 증거금, 거래 인도 시간 등의 차이에 있습니다.',
    63: '선물',
    64: '미래가 무엇입니까?',
    65: '선물이라고 하는 선물 계약은 시간을 초월하는 거래 방법입니다. 계약을 체결함으로써 구매자와 판매자는 지정된 시간, 가격 및 기타 거래 조건에 따라 지정된 양의 현물을 인도하는 데 동의합니다. ',
    66: '신뢰재무관리',
    67: '신탁 금융이란 무엇입니까?',
    68: '분권형 금융은 일종의 블록체인 기반 금융입니다. 금융 도구를 제공하기 위해 브로커, 거래소 또는 은행과 같은 금융 기관에 의존하지 않고 블록체인의 스마트 계약을 사용하여 금융 활동을 수행합니다',
    69: 'C2C',
    70: 'C2C란 무엇인가',
    71: '개인 간 거래\'라고도 하는 C2C는 거래의 한 형태이며 개인 대 개별 거래의 형태를 나타냅니다',
    72: '원스톱 거래 서비스',
    73: '이름',
    74: '가격',
    75: '24시간 기복',
    76: '24시간 볼륨',
    77: '인용',
    78: '안정적이고 안전한 집계',
    79: '금융 등급 보안 보호',
    80: ' 사용자 개인 정보 보호, 다중 서명, 계층 아키텍처 및 기타 보안 설계를 위한 편견 없는 영지식 증명 개인 정보 보호 및 기밀성 기술. ',
    81: '고성능 매칭 엔진',
    82: '고급 분산 클러스터 아키텍처 및 마이크로서비스 개발 방법을 채택합니다. 초당 3백만 개 이상의 트랜잭션을 동시에 지원합니다. ',
    83: '24시간 중단 없는 서비스',
    84: '고객 서비스는 하루 24시간 연중무휴로 온라인에 접속하여 다양한 질문에 답변해 드립니다. ',
    85: '언제 어디서나 거래 열기',
    86: '언제든지 다양한 시나리오의 거래 요구를 충족하기 위한 새로운 개정판',
    87: '24*7 전천후, 풀타임 운영 모드, 고객 서비스는 가능한 한 빨리 관련 문의에 답변할 것입니다. ',
    88: '트레이딩 페어',
    89: '최신 가격',
    90: '변화',
    91: '최고가',
    92: '최저가',
    93: '볼륨',
    94: '로그인',
    95: '등록',
    fee: {
        1: '거래 수수료율',
        2: '통화 거래 수수료',
        3: '계약 거래 수수료'
    },
    law: {
        1: '법적 진술',
        2: '첫 번째 기사',
        3: '이 플랫폼은 사용자를 위해 규정을 준수하고 안전하며 신뢰할 수 있는 디지털 자산 거래 환경을 조성하기 위해 최선을 다하고 있으며 사용자가 이 웹사이트를 사용하여 자금 세탁, 밀수, 상업적 뇌물, 악의적인 시장 조작 및 기타 불법 거래 활동 또는 불법적인 행위를 하는 것을 금지합니다. 활동. 이를 위해 우리는 엄격한 자금 세탁 및 테러 방지 프로그램을 수립하고 효과적인 보안 위험 통제 조치를 취하여 사용자의 합법적 인 권익 보호를 극대화하고 플랫폼에서 좋은 브랜드 평판을 유지하기 위해 최선을 다합니다. 그 자체',
        4: '두 번째 기사',
        5: '주관당국이 해당 조사 문서를 생성하고 이 웹사이트에 지정된 사용자의 조사에 협조할 것을 요청하거나 사용자 계정을 봉인, 동결 또는 이전하는 등의 조치를 취하는 경우, 이 웹사이트는 사법 문서의 유효성 및 진위 여부를 확인합니다. , 플랫폼이 해당 사용자 데이터 제공 또는 해당 작업 수행을 지원하기 위해 결정한 권한 있는 기관의 요구 사항에 따라 계정 운영 불능으로 인한 사용자 개인 정보 유출 및 사용자에게 발생한 기타 손실 등, 이 웹사이트 우리는 어떠한 책임도 지지 않습니다. ',
        6: '제3조',
        7: '이 웹사이트의 서비스를 사용하는 모든 사용자는 거래 전에 알아야 합니다. 디지털 자산은 정부나 중앙 은행의 지원을 받지 않으며 디지털 자산의 가격은 크게 변동합니다. 디지털 자산을 보유하거나 사용하는 것은 위험이 더 높습니다. 거래 결정을 내리기 전에 자신의 재정 상황을 합리적이고 신중하게 판단하여 자신에게 적합한지 판단해야 합니다',
        8: '제4조',
        9: '어떤 방식으로든 이 웹사이트에 로그온하거나 이 웹사이트의 서비스를 직간접적으로 사용하는 사람은 이 웹사이트 진술의 제약을 자발적으로 수락하는 것으로 간주됩니다. '
    },
    rules: {
        1: '거래 규칙',
        2: '주문을 생성한 후 구매자는 다음을 수행해야 합니다.',
        3: '1, 주문에 지정된 결제 시간 내에 결제를 완료하고 "결제했습니다"를 클릭합니다.',
        4: '2, 내 실명 결제 수단을 사용하여 주문 금액과 주문에 지정된 결제 계정에 따라 실시간으로 주문 결제를 완료합니다.',
        5: '3, 주문대금을 비실시간 결제수단으로 선택하실 경우 사전에 판매자의 동의를 받으셔야 합니다',
        6: '4, "디지털 화폐, BTC, 비트코인" 및 기타 정보를 지불 주문서에 기재해서는 안 됩니다.',
        7: '판매자가 따라야 할 원칙',
        8: '주문이 생성된 후 판매자는',
        9: '1, 플랫폼에서 인증한 수신 계정을 제공합니다.',
        10: '2, 구매자의 전액 지불을 받은 후 주문에 지정된 시간 내에 릴리스를 완료합니다.',
        11: '3, 구매자의 동의 없이 구매자/고객 서비스는 주문 취소를 요구하지 않습니다.',
        12: '4, 구매자의 동의 없이 생성된 주문 가격에 대해 이의가 없습니다.'
    },
    help: {
        1: '초보자 도움말',
        2: '주문 제한',
        3: '지정된 가격 이상으로 매수/매도하는 주문. ',
        4: '시장 주문',

        5: '현재 시장 가격으로 즉시 매수 또는 매도하는 주문. ',
        6: '손절매 제한 주문',
        7: ' 손실을 통제하기 위해 시장이 일정 가격에 도달한 후 정해진 가격으로 포지션을 매도하거나 청산하는 주문. ',
        8: '지정가 이익실현 주문',
        9: '지정가 지정가 주문과 유사하게 시장이 특정 가격에 도달한 후 정해진 가격으로 포지션을 매도하거나 청산하는 주문으로 이익을 통제하는 데 사용됩니다. ',
        10: '시장 손절 주문',
        11: ' 손실을 통제하기 위해 시장이 특정 가격에 도달한 후 시장 가격으로 포지션을 매도하거나 청산하는 주문. ',
        12: '시가 차익실현 주문',
        13: '시장 손절매 주문과 유사하게, 시장이 특정 가격에 도달한 후 시장 가격으로 포지션을 매도하거나 청산하는 주문이며, 이는 이익을 통제하는 데 사용됩니다. ',
        14: '포지션을 여는 마진은 얼마입니까? ',
        15: ' 증거금 거래란 계약을 사고 팔 때 일정 비율의 증거금만 지불하면 되는 것을 의미합니다. 선택한 레버리지에 따라 증거금 비율은 계약 금액의 작은 배수입니다. 이익을 확대할 수 있지만 추가 위험에 노출될 수도 있습니다. ',
        16: '청산이란 무엇이며 펑크는 무엇입니까? ',
        17: '귀하의 계정에 남아 있는 자금이 거래 계약에서 요구하는 유동 손익보다 적은 경우, 청산이라고도 하는 거래소에서 귀하의 포지션을 강제로 폐쇄합니다. 포지션 청산 시 가격은 시장 가격에 따라 결정되기 때문에 거래 가격이 극도로 불리할 경우 거래 손실을 충당할 자금이 부족하여 계정에 마이너스 가치가 발생할 수 있습니다. 포지션 쇼팅이라고 합니다. '
    },
    96: '거래 쌍 선택',
    97: '가격 확인',
    98: '주문서 작성 후 구매',
    99: '계정 로그인',
    100: '돌아온 것을 환영합니다! 이메일 또는 전화번호로 로그인',
    101: '사서함',
    102: '모바일',
    103: '비밀번호를 잊어버리셨나요? ',
    104: '지금 등록',
    105: '비밀번호',
    106: '전화번호를 입력하세요',
    107: '휴대전화 번호 형식 오류',
    108: '이메일을 입력하세요',
    109: '사서함 형식 오류',
    110: '비밀번호를 입력하세요',
    111: '비밀번호는 6자 이상입니다',
    112: '비밀번호는 최대 32비트입니다.',
    113: '언어 및 지역',
    114: '{appName} 계정 만들기',
    115: '이메일 또는 휴대폰 번호를 사용하여 등록하세요',
    116: '추천 코드',
    '116x': '지역 식별자',
    117: '동의',
    118: '그리고',
    119: '이용계약',
    120: '개인정보 보호정책',
    121: '등록? ',
    122: '인증 코드',
    123: '보내기',
    124: '인증 코드를 입력하십시오',
    125: '인증 코드는 6자리여야 합니다',
    126: '비밀번호 재설정',
    127: '알았어',
    128: '이메일 또는 휴대폰을 사용하여 로그인 비밀번호 재설정',
    129: '실명인증',
    130: '실명 인증을 완료하면 계정 보안을 보호하고 출금 한도 및 거래 권한을 높이는 데 도움이 됩니다',
    131: '인증하러 가기',
    132: '인증',
    133: '로그인, 코인 출금, 비밀번호 검색, 보안 설정 수정에 사용',
    134: '확인됨',
    135: '비밀번호 관리',
    136: '로그인 비밀번호',
    137: '펀드 비밀번호',
    138: '계정 보안을 보호하기 위해 사용',
    139: '자금의 안전을 보호하기 위해 사용',
    140: '개인 설정',
    141: '색상 선호도',
    142: '설정',
    143: '수정',
    144: '촛대 차트를 설정하고 색상을 매수하고 아래로 매수',
    145: '가격 책정 방법',
    146: '계정 자산 평가 단위,',
    147: '기본',
    148: '녹색은 뜨고 빨간색은 진다',
    149: '빨간색 위 및 녹색 아래',
    150: '로그인 비밀번호 수정',
    151: '펀드 비밀번호 수정',
    152: '이전 암호',
    153: '새 비밀번호',
    154: '이전 비밀번호를 입력하세요',
    155: '새 전화번호',
    156: '새 우편함',
    157: '휴대전화 번호 묶기',
    158: '바인드 사서함',
    159: '휴대전화 번호 수정',
    160: '사서함 수정',
    161: '인증됨',
    162: '닉네임 설정',
    163: '닉네임을 입력해주세요',
    164: '취소',
    165: '국가',
    166: '이름',
    167: '성',
    168: '인증서 유형',
    169: '인증 번호',
    170: '국가를 선택하세요',
    171: '이름을 입력하세요',
    172: '성을 입력하세요',
    173: 'ID 번호를 입력하세요',
    174: '신분증 앞면 사진을 올려주세요',
    175: '신분증 뒷면 사진을 올려주세요',
    176: '손에 들고 있는 신분증 사진을 올려주세요',
    177: '제출',
    178: '인증',
    179: '아이디',
    180: '여권',
    181: '다음',
    182: '증명사진 업로드 및 인증 완료',
    183: '업로드 실패',
    184: '이미지 형식 오류',
    185: '그림이 너무 큽니다',
    186: '로드 중',
    187: '자산 개요',
    188: '전송',
    189: '철수',
    190: '예금 동전',
    191: '재무 기록',
    192: '동결',
    193: '사용 가능',
    194: '총',
    195: '소액 숨기기',
    196: '통화',
    197: 'USDT 평가',
    198: '검색',
    199: '충전 네트워크',
    200: '입금 주소',
    201: '코인 아웃 주소',
    202: '충전량',
    203: '코인 입금 스크린샷',
    204: '비고',
    205: '주소 복사',
    206: '복사 성공',
    207: '리턴',
    208: '선택적',
    209: '충전할 코인 개수를 입력하세요',
    210: '유효한 번호를 입력하세요',
    211: '통화 주소를 입력하세요',
    212: '코인 입금 스크린샷을 업로드하세요',
    213: '출금 주소',
    214: '출금 금액',
    215: '사용 가능한 잔액',
    216: '모두',
    217: '취급 수수료',
    218: '출금 코인 금액을 입력하세요',
    219: '펀드 비밀번호를 입력하세요',
    220: '펀드 비밀번호는 6자리여야 합니다',
    221: '기본 비밀번호',
    222: '아직 해당 지갑을 묶지 않았습니다! ',
    223: '추가로 이동',
    224: '제출 성공',
    225: '컬렉션 지갑 추가',
    226: '주소',
    227: '네트워크를 입력하십시오',
    228: '주소를 입력하세요',
    229: '네트워크',
    230: '출금 네트워크',
    231: '주소 길이가 가장 낮은 32비트입니다',
    232: '자금 이체',
    233: '이체 금액',
    234: '이체 금액을 입력하세요',
    235: '불충분한 균형',
    236: '이전 가능',
    237: '스와이프 방향',
    238: '전송',
    239: '에서',
    240: '~에',
    241: '재무 기록',
    242: '코인 입금 기록',
    243: '출금 기록',
    244: '기록 전송',
    245: '수량',
    246: '상태',
    247: '생성된 시간',
    248: '시간 확인',
    249: '더 이상',
    250: '가격',
    253: '트레이딩 페어',
    254: '변화',
    255: '아직 데이터가 없습니다',
    256: '누적',
    257: '높음',
    258: '낮음',
    259: '거래량',
    260: '열다',
    261: '받기',
    262: '금액',
    263: '선물 거래',
    264: '강세하다',
    265: '약세',
    266: '제품 이름',
    267: '계약서 보기',
    268: '구매 금액',
    269: '구매 금액을 입력하세요',
    270: '거래 수수료',
    271: '최소 구매',
    272: '상태',
    273: '배송 대기 중',
    274: '배달',
    275: '주문이 완료되었습니다',
    276: '제품명',
    277: '손익',
    278: '구매 가격',
    279: '배달 가격',
    280: '거래 계속',
    283: '이익',
    284: '주문 확인',
    285: '역사 주문',
    286: '주문 보류',
    287: '생성된 시간',
    288: '구매',
    289: '팔다',
    290: '거래 횟수',
    291: '거래 금액',
    292: '계약',
    293: '배달 시간',
    294: '배송비',
    295: '초',
    296: '분',
    297: '시',
    298: '날',
    299: '남은 시간',
    300: '더보기',
    301: '통화 거래',
    302: '판매량',
    303: '판매금액을 입력하세요',
    304: '피아트 통화 거래',
    305: '자산',
    306: '로그아웃',
    307: '성공적으로 등록되었습니다',
    308: '추천을 입력하세요',
    '308x': '지역 식별 코드를 입력하세요',
    309: '성공',
    310: '실패',
    311: '확인',
    312: '결제수단',
    313: '출금 주소',
    314: '삭제',
    315: '작전',
    316: '당신의 지갑',
    317: '정말 삭제하시겠습니까? ',
    318: '성공적으로 삭제',
    319: '인증 실패',
    320: '재인증',
    330: '디지털 자산 출금 지갑',
    331: '은행 카드 묶기, 현금을 법정 화폐로 인출',
    332: '올바른 주소를 입력하십시오',
    333: '가상 화폐',
    334: '외환',
    335: '귀금속',
    1000: '일',
    1001: '진행 중',
    1002: '닫힌',
    1003: '규칙',
    1004: '제품 이점',
    1005: '사용한 만큼 저장',
    1006: '배당 만료',
    1007: '일일 보너스',
    1008: '실시간 예금이자',
    1009: '100% 자본 보장',
    1010: '휴일 정상수입',
    1011: '다음 날 휴식',
    1012: '제품 이점',
    1013: '회원은 200,000 USDT를 플랫폼에 예치하고 14일의 기간과 1일 수입이 0.5%인 자산 관리 상품을 선택하고 1일 수입은 다음과 같습니다. 200,000*0.5%=1,000USDT 즉, 14일 후 14,000 USDT의 수입을 얻을 수 있으며 수입은 매일 발행되며 원금을 입금하면 만기 시 자동으로 귀하의 계좌로 반환됩니다.',
    1014: '보호 명령',
    1015: '호스팅',
    1016: '완료',
    1017: '구속됨',
    1018: '아직 데이터가 없습니다',
    1019: '구매 금액',
    1020: '일일 수익률',
    1021: '신뢰.6',
    1022: '결제 시간',
    1023: '교환 날짜',
    1024: '만료',
    1025: '최근(일일 수입)',
    1026: '배당 시간',
    1027: '매일',
    1028: '보관 금액',
    1029: '만료 시 반환',
    1030: '사용 가능한 잔액',
    1031: '예상 총 수입',
    1032: '자금 송금',
    1033: '모두',
    1034: '단일 제한',
    1035: '취급 수수료',
    1036: '읽고 동의했습니다',
    1037: '플랫폼 재무 관리 서비스 계약',
    1038: '알았어',
    1039: '구매',
    1040: '구매',
    1041: '판매',
    1042: '은행 송금',
    1043: '금액을 입력하세요',
    1044: '피아트 통화 카테고리',
    1045: '판매량',
    1046: '최대 판매 가능',
    1047: '모두',
    1048: '참고 단가',
    1049: '결제 수단',
    1050: '은행 이름',
    1051: '은행 계좌 번호',
    1052: '이름',
    1053: '해당 결제 수단을 제한하지 않아 판매할 수 없습니다. ',
    1054: '주문 기록',
    1055: '필터',
    1056: '유형',
    1057: '상태',
    1058: '처리 중',
    1059: '통과',
    1060: '실패',
    1061: '수량',
    1062: '성공',
    1063: '작업 수행',
    1064: '오늘의 결과 계산',
    1065: '누적 소득',
    1066: '구속 명령',
    1067: '신뢰 계정이 열리지 않았습니다',
    1068: '커뮤니케이션 신탁 계좌를 개설하고 안정적인 수동 소득을 누리고 자산 평가를 더 쉽게하십시오. ',
    1069: '활성화 신청은 귀하가 이를 읽고 동의했음을 의미합니다',
    1070: '"플랫폼 재무 관리 서비스 계약"',
    1071: '활성화 신청',
    1072: '취소',
    1073: '실행 중...',
    1074: '구속',
    1075: '정말 교환하시겠습니까? ',
    1076: '조기 상환은 청산된 손해의 지불을 요구하며, 이때 귀하의 재정 관리 유형에 따라 공제됩니다',
    1077: ', 상환이 확인 되었습니까?',
    1078: '보낸',
    1079: '인증 코드를 입력하세요',
    1080: '프롬프트',
    1081: '이익',
    1082: '시간',
    1083: '법정 통화 결제 방법 추가',
    1084: '주문 상태',
    1085: '통화',
    1086: '법적 통화의 수',
    1087: '은행 계좌 번호',
    1088: '뚱뚱한 돈 수집 방법',
    1089: '수집 방법 추가',
    1090: '기존 결제 방법',
    1091: '화폐 화폐',
    1092: '은행 코드',
    1093: '결제 수단을 삭제하시겠습니까? ',
    1094: '은행 계좌 번호를 입력하십시오',
    1095: '은행 이름을 입력하십시오',
    1096: '법정 통화를 입력하십시오',
    1097: '선택하세요',
    1098: '이름을 입력하세요',
    1099: '가지',
    1100: '무제한',
    1101: '아직 실명을 확인하지 않았습니다',
    1102: '백서',
    1103: '우리의 특징',
    1104: '우리는 안전합니다',
    1105: '우리는 강력합니다',
    1106: '최고의 지원',
    1107: '우리는 글로벌',
    1108: '작동 방식',
    1109: '20개 이상의 국가 및 지역의 사용자가 당사 플랫폼을 사용하고 있으며 널리 인정받고 있습니다',
    1110: '고급 데이터 암호화',
    1111: '다양한 암호화폐',
    1112: '90개 이상의 암호화폐 상장',
    1113: '귀하의 거래 데이터는 종단 간 암호화를 통해 보호되므로 귀하만 개인 정보에 액세스할 수 있습니다.',
    1114: '430억',
    1115: '24시간 거래량',
    1116: '3천만',
    1117: '우리를 신뢰하는 등록된 사용자',
    1118: '최저 거래 수수료',
    1119: '다운로드',
    1120: '금융기관명',
    1121: '지점 이름',
    1122: '지점 번호',
    1123: '계좌 번호',
    1124: '수취인 이름',
    1125: '금초',

    1148: '가장 신뢰할 수 있는 암호화폐 플랫폼',
    1149: '우리를 선택해야 하는 이유는 다음과 같습니다.',
    1150: '우리는 안전합니다',
    1151: '사용자 개인 정보 보호, 다중 서명, 계층 아키텍처 및 기타 보안 설계를 위한 편견 없는 영지식 증거 개인 정보 보호 및 기밀성 기술',
    1152: '최고의 지원',
    1153: '24*7시간 전천후, 풀타임 운영 모드, 고객 서비스는 가능한 한 빨리 관련 문의에 답변합니다',
    1154: '우리는 글로벌',
    1155: '20개 이상의 국가 및 지역에서 사용자가 당사 플랫폼을 사용하고 있으며 널리 인정받고 있습니다. ',
    1156: '우리는 강력하다',
    1157: '가장 인기 있는 디지털 자산 거래 플랫폼, 시장에서 가장 강력하고 편리한 기술 분석 및 거래 도구에 대한 액세스',
    1158: '다양한 암호화폐',
    1159: ' 90개 이상의 암호화폐 상장',
    1160: '고급 데이터 암호화',
    1161: '귀하의 거래 데이터는 종단 간 암호화를 통해 보호되므로 귀하만 귀하의 개인 정보에 액세스할 수 있습니다. ',
    1162: '우리는 가장 인기 있는 다양한 디지털 통화를 지원합니다.',
    1163: '많은 국가에서 현지화 거래를 설정했습니다.',
    1164: '법적 통화를 사용하여 디지털 자산을 구매하고 디지털 자산의 세계로 빠르게 진입하십시오.',
    1165: '언제 어디서나 거래하세요.',
    1166: '나를 스캔하여 다운로드',
    1167: '환율',
    1168: '최근 업로드된 환율로 250개 이상의 암호화폐 매매',
    1169: '앱 및 데스크톱 클라이언트로 최신 정보 유지',
    1170: '이름',
    1171: '가격',
    1172: '변화',
    1173: '차트',
    1174: '거래',
    1175: '세부 사항',
    1176: '이었다',
    1177: '안전한',
    1178: '우리가 있다',
    1179: '글로벌',
    1180: '강한',
    1181: '풍부한 다양성',
    1182: '암호화폐',
    1183: '고급 데이터',
    1184: '암호화',
    1185: '시작할 준비가되었습니다',
    1186: '우리는 세계 최고의 디지털 자산 거래 플랫폼입니다.',
    1187:'우리는 세계 최고의 디지털 자산 거래 플랫폼입니다. 우리는 고객의 디지털 자산의 보안을 보장하기 위해 보안 기능을 개선하기 위해 끊임없이 노력합니다. 우리의 모델은 고객에게 지속 가능한 가치를 지닌 프로젝트를 제공하는 것입니다.',

    1188: '총 공급',
    1189: '24*7 전천후, 풀타임 작동 모드, 고객 서비스에서 가능한 한 빨리 관련 문의에 답변해 드립니다.',
    1190: '우리는 글로벌',
    1191: '우리는 강하다',
    1192: '가장 인기 있는 디지털 자산 거래 플랫폼으로 시장에서 가장 강력하고 편리한 기술 분석 및 거래 도구를 얻으십시오.',
    1193: '다양한 암호화폐',
    1194: '귀하의 거래 데이터는 종단 간 암호화로 보호되어 귀하만 개인 정보에 액세스할 수 있습니다.',
    1195: '최소 거래 수수료',
    1196: '등록된 사용자를 신뢰하십시오',
    1197: '24시간 거래량',
    1198: '+420억',
    1199: '+3천만',
    1200: '여러 국가에서 여러 비즈니스 형태를 통합하는 블록체인 생태계를 만들기 위해 현지화된 거래 서비스 센터를 설립했습니다. ',
    1201: '법정 화폐를 사용하여 디지털 자산을 구매하고 디지털 자산의 세계에 빠르게 진입하십시오.',
    1202: '앱 다운로드',
    1203: '우리의 장점',
    1204: '풍부한 통화 및 통화 정보, 통화, 계약, 법적 통화 거래 및 기타 거래 방법, 전문 시장 및 깊이 제공.',
    1205: '규제 라이센스'
}